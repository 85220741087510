.datepicker-container {
  position: relative;
  border-radius: 8px;
  background-color: #f8f8f8;
}

.datepicker-container > .icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  color: #215df6;
  pointer-events: none;
  font-size: 18px;
}

.react-datepicker__input-container > input {
  background-color: #f8f8f8;
  border: 0;
  padding: 5px 10px;
  border-radius: 8px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.1px;
  width: max-content;
  cursor: pointer;
}

.react-datepicker-popper {
  box-shadow: 2px 16px 50px 0 rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 12px 10px;
  background-color: #ffffff;
  z-index: 99;
}
.header {
  position: relative;
}
.header > .selectYearContainer {
  position: absolute;
  width: 260px;
  top: 34px;
  left: -10px;
  background-color: #ffffff;
  z-index: 1;
}

.header > .selectYearContainer > .selectYearWrapper {
  width: 250px;
  overflow: hidden;
  background-color: #ffffff;
}
.header > .selectYearContainer > .selectYearWrapper > .year-select {
  width: 270px;
  display: flex;
  flex-wrap: wrap;
  transition: "0.1s";
  column-gap: 14px;
  row-gap: 6px;
  overflow-y: auto;
  overflow-x: hidden;
}

.year-data {
  width: 52px;
  padding: 6px 8px;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 18.29px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
}

.year-data:hover {
  background-color: #215df61a;
}

.react-datepicker-month-select {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.react-datepicker-month-select > span {
  font-size: 13px;
  line-height: 19px;
  color: #215df6;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  margin-right: 2px;
}

.react-datepicker__month {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.react-datepicker__week {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 6px;
}

.react-datepicker__day-names {
  display: flex;
  column-gap: 6px;
}
.react-datepicker__day-name {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #b4b4b4;
  font-size: 14px;
  font-weight: 500;
  line-height: 12.19px;
  font-family: Montserrat, sans-serif;
  text-align: center;
}

.react-datepicker__day {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;

  color: #333333;
  font-size: 14px;
  font-weight: 500;
  line-height: 12.19px;
  font-family: Montserrat, sans-serif;
  text-align: center;
}

.react-datepicker__day:hover {
  background-color: #215df61a;
}

.react-datepicker__day--selected {
  background-color: #215df6;
  color: #ffffff;
}

.react-datepicker__day--selected:hover {
  background-color: #005cff;
  color: #ffffff;
}

.react-datepicker__day--outside-month {
  opacity: 0.3;
}

.react-datepicker__day--disabled {
  color: #333333;
  opacity: 0.3;
}

.month-name {
  margin-left: 4px;
  margin-right: 4px;
  color: #000000;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: center;
  user-select: none;
  width: 90px;
}

.select-Bgc {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #eeeeee;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.react-datepicker__aria-live {
  display: none !important;
}
