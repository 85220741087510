@import '../../../style/main.scss';

.testModalContainer {
  box-sizing: border-box;
  height: 700px;
  .header {
    padding-bottom: 16px;
    border-bottom: 1px solid $gs-002;
    margin-bottom: 16px;

    .title {
      @include Noto-Sans-TCTitle243-Bold;
      color: $primary-color;
      display: flex;
      align-items: center;
      .icon {
        margin-right: 16px;
      }
    }
  }
  .body {
    width: 100%;
    height: 550px;
    border-bottom: 1px solid $gs-002;

    .title {
      color: $gs-006;
      @include Noto-Sans-TCCaption131-Regular;
      margin-bottom: 8px;
    }
    .name {
      @include Noto-Sans-TCCallout143-Bold;
      line-height: 25px;
      margin-bottom: 24px;
    }
    .testList {
      .testListTitle {
        @include Noto-Sans-TCCallout153-Bold;
      }
      .table {
        margin-top: 16px;
        width: 100%;
        th:nth-child(1) {
          border-radius: 10px 0px 0px 10px;
          background-color: $system-pgrey;
          color: $gs-006;
          padding: 10px 16px;
          @include Noto-Sans-TCCaption131-Regular;
        }
        th:nth-child(2) {
          border-radius: 0px 10px 10px 00px;
          background-color: $system-pgrey;
          color: $gs-006;
          padding: 10px 16px;
          @include Noto-Sans-TCCaption131-Regular;
        }
        tbody {
          margin-top: 10px;
          td {
            // height: 36px;
            padding: 6px;
            color: $gs-008;
            @include Noto-Sans-TCCallout143-Bold;
            &:nth-child(1) {
              padding-left: 16px;
            }
            .input {
              background: $gs-002;
              border: 1px solid $gs-003;
              border-radius: 8px;
              width: 98%;
              height: 36px;
              padding: 0 10px;
            }
            .errInput {
              background: $gs-002;
              border: 1px solid red;
              border-radius: 8px;
              width: 98%;
              height: 36px;
              padding: 0 10px;
            }
            .errorText {
              display: block;
              color: $system-red;
            }
          }
        }
      }
    }
  }
  .footer {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
    button:first-child {
      margin-right: 4px;
    }
  }
}
