@import "../../../../style/main.scss";

.textField {
  @include Noto-Sans-TCBody161-Regular;
  width: 100%;
  padding: 6px 10px;
  background: $gs-001;
  border-radius: 8px;
  resize: none;
  color: $gs-008;
  border: 1px solid $gs-002;
  box-sizing: border-box;
  &:focus {
    background: $gs-000;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }
  &::placeholder {
    color: $gs-006;
  }
  &:hover {
    background: $gs-002;
  }
}
