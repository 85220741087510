@import "../../../style/main.scss";

.modal {
  display: none;
}

.modalOpen {
  box-sizing: border-box;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: $zIndex-modal;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
}

/* Modal Content/Box */
.modalContent {
  background-color: #fefefe;
  padding: 24px;
  border-radius: 16px;
  width: 368px;
  height: 315px;
  position: absolute;
  top: 85px;
  right: 30px;
}

@media (max-width: $media-xl) {
  .modalContentMd {
    height: 627px;
  }
}

@media (max-width: $media-lg) {
  .modalContentMd {
    padding: 32px;
    width: 280px;
    height: 567px;
  }
}

@media (max-width: $media-sm) {
  .modalContentMd {
    padding: 20px;
    width: 280px;
    height: 441px;
  }
}
