@import '../../../style/main.scss';
* a {
  text-decoration: none;
}
.leftNavigation {
  display: block;
  width: 250px;
  height: 100vh;
  position: fixed;
  z-index: 99;
  background-color: $system-pgrey;
  padding: 16px 12px 16px 16px;

  .title {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 20px;
    font-weight: 700;
    @include Montserrat-Medium;
    background: linear-gradient(91.8deg, $primary-color 7.46%, #af52de 51.53%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  .serviceBtnListDetail {
    margin-bottom: 4px;
    a {
      color: $gs-008;
    }
  }
  .serviceBtn {
    @include Noto-Sans-TCFootnote152-Medium;
    color: $gs-008;
    padding: 12px 12px 12px 16px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 8px;
    &.active,
    &:hover {
      background-color: $primary-color;
      color: $gs-000;
      transition: 0.5s;
    }
    &.active > .serviceIcon,
    &:hover > .serviceIcon {
      filter: brightness(0) invert(1);
    }
    button {
      position: absolute;
      bottom: 24px;
    }
  }
  .serviceBtnContent {
    display: inline;
    padding: -8px;
    text-decoration: none;
  }
  .serviceList {
    cursor: pointer;
    margin: 8px 0px 0px 53px;
    padding: 3px 8px;
    @include Montserrat-Medium;
    font-weight: 600;
    font-size: 11px;
    border-radius: 12px;
    line-height: 26px;
    // background: $gs-000;
    &.active,
    &:hover {
      background-color: $gs-003;
      transition: 0.5s;
    }
  }
  .serviceIcon {
    margin-right: 18px;
    width: 24px;
    height: 24px;
    color: $primary-color;
  }
  .naviBottom {
    position: absolute;
    bottom: 0;
    height: 200px;
    width: 230px;
    border-top: 1px solid $gs-003;
    padding-left: 12px;
    background: $system-pgrey;

    .adminBlock {
      padding-top: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .adminTitle {
        @include Montserrat-Semi-Bold;
        font-size: 14px;
        color: $gs-005;
        line-height: 25px;
      }
      .adminName {
        @include Noto-Sans-TCBody161-Regular;
        line-height: 25px;
      }
      .adminLastName {
        @include Noto-Sans-TCCallout162-Medium;
        margin-right: 16px;
        width: 38px;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $gs-000;
        background-color: $system-orange;
        border-radius: 50%;
        text-transform: uppercase;
      }
    }
    button {
      position: absolute;
      bottom: 50px;
      left: -2px;
      width: 97.5%;
    }
  }
}
.leftIcon {
  position: absolute;
  top: 5%;
  left: 268px;
  z-index: 111;
}
.rightIcon {
  position: absolute;
  top: 5%;
  left: 20px;
  z-index: 999;
}

.top-navigation {
  height: 48px;
  position: fixed;
  background: $gs-008;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $zIndex-topNavigation;
  width: 100%;
  opacity: 1;
  visibility: visible;
  left: 0;
  top: 0;
  transition: 0.8s;
  font-size: 14px;
  line-height: 26px;
  a {
    text-decoration: none;
  }
  h4 {
    @include Noto-Sans-TCCallout141-Regular;
    @include Montserrat-Medium;
    font-weight: 500;
    color: $gs-003;
  }
  a,
  h4:hover {
    color: $gs-000;
    cursor: pointer;
  }

  .content {
    width: 944px;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;

    .logo {
      color: white;
      @include Montserrat-Medium;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }

    .hamburgerMenuButton {
      display: none;
    }
    .highlight {
      color: $gs-000;
    }
    .mainMenu {
      height: 100%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      h4 {
        padding: 0 28px;
      }

      .parentMenu {
        position: relative;
        align-items: center;
        height: 100%;
        display: flex;
        .megaMenus {
          top: 44px;
          left: -20px;
          display: none;
          flex-direction: column;
          align-items: center;
          padding: 8px 12px;
          position: absolute;
          flex-grow: 1;
          min-width: 200px;

          background: rgba(255, 255, 255, 0.9);
          box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
          backdrop-filter: blur(30px);

          border-radius: 16px;
          .menuContent {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 8px;
            h4 {
              padding: 0;
              color: $gs-008;
            }
          }
        }
        .megaMenus:hover {
          display: flex;
        }
      }

      .parentMenu:hover {
        .megaMenus {
          display: flex;
        }
      }
    }
    .footer {
      min-width: 100px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      h4 {
        padding-left: 20px;
      }
    }
  }
}
.hamburgerMenu {
  height: calc(100vh - 48px);
  position: fixed;
  background: $gs-009;
  display: none;
  z-index: $zIndex-hamburgerMenu;
  width: 100%;
  top: 48px;
  transition: 0.8s;
  @include Noto-Sans-TCCallout141-Regular;
  @include Montserrat-Medium;
  font-weight: 500;
  flex-direction: column;

  a {
    text-decoration: none;
  }
  h4 {
    @include Noto-Sans-TCCallout141-Regular;
    color: $gs-003;
  }
  a,
  h4:hover {
    color: $gs-000;
    cursor: pointer;
  }
  .hamburgerMenuContent {
    padding: 12px 0;
    margin: 0 48px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  .buttonContent {
    padding: 12px 0;
    display: flex;
    justify-content: center;
    width: 100%;
    button {
      width: 256px;
    }
  }
}

@media (max-height: 732px) {
  .leftNavigation {
    padding: 16px 12px 16px 16px;
    .serviceList {
      font-size: 14px;
    }
  }
  .naviTop {
    overflow-y: scroll;
    max-height: calc(100vh - 270px);
    padding-right: 8px;
  }
}

@media (max-width: $media-xl) {
  .top-navigation {
    .content {
      width: 100%;
      margin: 0 56px;
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;
      justify-content: center;
      .hamburgerMenuButton {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 24px;
        color: white;
        width: fit-content;
      }
      .hamburgerMenuButton:hover {
        cursor: pointer;
      }
      .mainMenu {
        display: none;
      }
      .logo {
        text-align: center;
      }
      .footer {
        justify-content: flex-end;
      }
    }
  }
  // .leftNavigation {
  //   display: none !important;
  // }
  .hamburgerMenu {
    display: flex;
  }
}

@media (max-width: $media-lg) {
  .top-navigation {
    .content {
      margin: 0 20px;
      .footer {
        .logout {
          display: none;
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .leftIcon {
    display: none;
  }
  // .leftNavigation {
  //   display: none !important;
  //   // width: 0px;
  //   overflow: hidden;
  // }
}
