@import '../../../../style/main.scss';

.modal {
  display: none;
}

.modalOpen {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: $zIndex-modal;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
}

/* Modal Content Box */
.modalContentXl {
  background-color: #fefefe;
  border-radius: 16px;
  width: 70%;
}

.modalContentLg {
  background-color: #fefefe;
  padding: 48px;
  border-radius: 16px;
  width: 928px;
}

.modalContentMd {
  background-color: #fefefe;
  padding: 48px;
  border-radius: 24px;
  width: 624px;
  max-height: 90vh;
}

.modalContentSm {
  background-color: #fefefe;
  padding: 32px;
  border-radius: 16px;
  width: 30%;
  height: 200px;
}

@media (max-width: $media-xl) {
  .modalContentMd {
    height: 627px;
  }
}

@media (max-width: $media-lg) {
  .modalContentMd {
    padding: 32px;
    width: 280px;
    height: 567px;
  }
}

@media (max-width: $media-sm) {
  .modalContentMd {
    padding: 20px;
    width: 280px;
    height: 441px;
  }
}
