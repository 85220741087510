@import "../../../../style/main.scss";

.inputOuter {
  display: inline-block;
  position: relative;
  width: 297px;
  .input {
    background: $gs-001;
    width: 100%;
    height: 36px;
    border: 1px solid $gs-002;
    border-radius: 8px;
    padding: 12px 10px;
    font-size: 15px;
    box-sizing: border-box;
    &:disabled {
      cursor: not-allowed;
      color: $system-grey;
      &:hover {
        background: $gs-001;
      }
    }
    &:hover {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.05),
          rgba(0, 0, 0, 0.05)
        ),
        #f8f8f8;
    }
    &::placeholder {
      // color: $gs-006;
      color: $system-grey;
    }
  }

  .onInput {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    background: $gs-000;
  }

  .inputError {
    border: 1px solid red;
  }

  .deleteIcon {
    position: absolute;
    top: 50%;
    right: 35px;
    transform: translateY(-50%);
    color: $gs-006;
    cursor: pointer;
  }
  .searchIcon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: $primary-color;
    width: 18px;
    height: 18px;
  }
}
