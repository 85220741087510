$primary-color: #5856d6;
$secondary-color: rgba(88, 86, 214, 0.2);
$background-color: #fcfcfc;
$third-color: rgba(243, 242, 248, 1);

$gs-000: #fff;
$gs-001: #f8f8f8;
$gs-002: #eee;
$gs-003: #ddd;
$gs-004: #c9c9c9;
$gs-005: #b4b4b4;
$gs-006: #999;
$gs-007: #666;
$gs-008: #333;
$gs-009: #000;
$system-red: #ff3b30;
$system-lightYellow: #ffefb0;
$system-orange: #ff9500;
$system-green: #34c759;
$system-yellow: #fc0;
$system-teal: #5ac8fa;
$system-blue: #007aff;
$system-indigo: $primary-color;
$system-pink: #ff2d55;
$status-pink: #fb8e88;
$status-backgroundPink: #fff3f2;
$system-purple: #af52de;
$system-grey: #b9b7bd;
$system-pgrey: #f3f2f8;
$system-table: rgba(88, 86, 214, 0.1);
$system-error: #ff766e;
$system-success: #4c9974;

$tdp-blue: #005cb2;
$tdp-green: #007d30;
$tdp-secondaryGreen: #80ae54;

$system-isActivateBg: rgba(251, 255, 242, 1);
$system-isActivateBorder: rgba(215, 242, 208, 1);

$system-notActivateBg: rgba(255, 242, 242, 1);
$system-notActivateBorder: rgba(242, 210, 208, 1);
