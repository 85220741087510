@import "../../../../style/main.scss";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 0;
  // margin-top: 10px;
  // border-top: 1px solid $gs-002;
  .text {
    @include Noto-Sans-TCCaption131-Regular;
    span {
      @include Montserrat-Medium;
      font-size: 13px;
      margin: 0 3px;
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  color: $gs-007;
  li {
    a {
      @include Montserrat-Medium;
      width: 23px;
      height: 23px;
      margin: 0 4px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 13px;
      &:hover {
        background: rgba(0, 0, 0, 0.1);
        transition: 0.3s;
      }
    }
    &.active {
      a {
        background-color: $primary-color;
        color: $gs-000;
        &:hover {
          background-color: $primary-color !important;
          color: $gs-000 !important;
        }
      }
    }
  }
}

.disabled {
  opacity: 0.2;
  a {
    cursor: not-allowed !important;
    background: none !important;
  }
}

.navigator {
  font-size: 23px;
  margin-top: 1px;
  color: $primary-color;
}
