@import '../../../../style/main.scss';

.notification-container {
  position: fixed;
  top: 0px;
  left: 0px;
  opacity: 0;
  height: 77px;
  width: 100%;
  display: flex;
  justify-content: center;
  transition: all 0.5s ease;
}
.notification-on {
  z-index: $zIndex-toastOn;
}
.notification-off {
  z-index: $zIndex-toastOff;
}
.icon {
  color: $system-green;
  font-size: 23px;
  margin-right: 6px;
}
.iconError {
  color: $system-red;
  font-size: 23px;
  margin-right: 6px;
}
.notification {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(30px);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
  border-radius: 44px;
  text-align: center;
  padding: 16px 28px 16px 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.notification-title {
  @include Noto-Sans-TCTitle163-Bold;
  background: linear-gradient(91.95deg, #843fa5 0%, #615c9c 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  display: flex;
  align-items: center;
}
.notification-subtitle {
  @include Noto-Sans-TCCaption131-Regular;
  color: $gs-006;
}
