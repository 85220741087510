@import "../../../../style/main.scss";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 0;
  .text {
    @include Noto-Sans-TCCaption131-Regular;
    color: $gs-007;
    span {
      @include Montserrat-Medium;
      font-size: 13px;
      margin: 0 3px;
      color: $gs-008;
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  color: $gs-007;
  li {
    a {
      @include Montserrat-Medium;
      width: 23px;
      height: 23px;
      margin: 0 4px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 13px;
      &:hover {
        background: rgba(0, 0, 0, 0.1);
        transition: 0.3s;
      }
    }
    &.active {
      a {
        background-color: $primary-color;
        color: $gs-000;
        &:hover {
          background-color: $primary-color !important;
          color: $gs-000 !important;
        }
      }
    }
  }
}

.disabled {
  opacity: 0.2;
}

.navigator {
  font-size: 23px;
  margin-top: 1px;
  color: $primary-color;
}

.marketingCampaignTableFoot,
.trackingTableFoot {
  margin-top: 0px;
  padding: 22px 0 22px 16px;
  background-color: $gs-000;
  border-radius: 0px 0px 18px 18px;
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.04);
}
