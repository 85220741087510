@import "../../../../style/main.scss";

.dropDownError {
  border: 2px solid rgba(255, 59, 48, 0.5);
}

.dropDown {
  width: 100%;
  height: 37px;
  padding: 6px 10px;
  background: $gs-001;
  border: 1px solid $gs-002;
  box-sizing: border-box;
  border-radius: 12px;
  color: $gs-008;
  cursor: pointer;

  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.dropDownNoBg {
    background: #ffffff;
  }

  span {
    display: flex;
    align-items: center;
    height: 28px;
    color: $primary-color;
    @include Noto-Sans-TCFootnote152-Medium;
  }

  .nonValueSpan {
    display: flex;
    align-items: center;
    height: 28px;
    color: $gs-005;
  }

  .dropdownIcon {
    color: $primary-color;
  }

  .btn-img {
    img {
      display: block;
      width: 24px;
      color: $primary-color;
    }
  }

  .dropDownList {
    border: 1px solid red;
    position: absolute;
    top: 48px;
    left: 0px;
    padding: 8px;
    box-sizing: border-box;
    background-color: $gs-000;
    width: 100%;
    box-shadow: 2px 16px 50px rgba(0, 0, 0, 0.3);
    border-radius: 14px;
    max-height: 204px;
    overflow-y: auto;
    display: block;
    z-index: $zIndex-dropdown;

    li:last-child {
      margin-bottom: 0px;
    }

    li {
      padding: 4px 6px;
      margin-bottom: 4px;
      border-radius: 6px;

      span {
        color: $gs-008;
        line-height: 28px;
        @include Noto-Sans-TCCaption131-Regular;
        &:hover {
          color: $primary-color;
        }
      }

      &:hover {
        background-color: $secondary-color;
      }
    }
  }

  .hidden {
    display: none;
  }

  .rotate180 {
    transform: rotate(180deg);
    transition: 0.2s;
  }
}
