@import "../../../../style/main.scss";

// .test {
//   position: relative;
// }

.tooltips {
  @include Noto-Sans-TCCaption121-Regular;
  line-height: 18px;
  padding: 6px 8px;
  border-radius: 6px;
  top: 0;
  width: 280px;
  left: 60px;
  position: fixed;
  z-index: 2001;
  background-color: $gs-008;
  color: $gs-000;
  word-wrap: "break-all";
  opacity: 0.9;
}

.dropDown {
  @include Noto-Sans-TCCallout151-Regular;
  // font-family: "Montserrat";
  font-weight: 500;
  width: 100%;
  height: 36px;
  padding: 6px 10px;
  background: $gs-001;
  box-sizing: border-box;
  border-radius: 10px;
  color: $primary-color;
  border: 1px solid $gs-002;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.wide {
    height: 43px;
    padding: 6px 12px;
    text-transform: uppercase;
    .dropDownList {
      top: 47px;
    }
    input {
      text-transform: uppercase;
    }
  }
  &.dropDownError {
    border: 1px solid $system-red;
  }
  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)),
      $gs-002;
  }
  &.light {
    right: 6px;
  }
  &.small {
    padding: 6px 10px;
    height: 39px;
  }
  &.lightsmall {
    background: $gs-001;
    padding: 6px 10px;
    height: 39px;
  }
  &.curve {
    @include Noto-Sans-TCCaption132-Medium;
    padding: 4px 8px 4px 12px;
    height: 32px;
    border-radius: 20px;
    color: $primary-color;
    .dropDownList {
      top: 32px;
      li {
        padding: 4px;
        input {
          font-size: 13px;
          border: 0;
          background-color: transparent;
        }
        &:hover {
          // background-color: $light-purple;
          color: $gs-008;
        }
        &.active {
          color: $primary-color;
        }
      }
    }
  }
  &.disabled {
    cursor: not-allowed;
  }
  input {
    display: flex;
    align-items: center;
    height: 28px;
    border: 0;
    background-color: transparent;
  }
  .nonValueSpan {
    display: flex;
    align-items: center;
    height: 28px;
    color: $gs-006;
    font-weight: 400;
    letter-spacing: 0.06px;
    line-height: 26px;
  }
  .btn-img {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      display: block;
      width: 24px;
    }
  }
  .dropDownList {
    position: absolute;
    // position: fixed;
    top: 36px;
    left: 0px;
    padding: 4px;
    box-sizing: border-box;
    background-color: $gs-000;
    width: 100%;
    box-shadow: 2px 16px 50px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    max-height: 204px;
    overflow-y: auto;
    overscroll-behavior: contain;
    display: block;
    z-index: 12;
    li:last-child {
      margin-bottom: 0px;
    }
    li {
      padding: 4px 8px;
      // margin-bottom: 4px;
      border-radius: 8px;
      position: relative;
      input {
        @include Noto-Sans-TCCallout151-Regular;
        width: 100%;
        border: 0;
        background-color: transparent;
        white-space: nowrap;
        text-overflow: ellipsis;
        pointer-events: none;
        &:hover {
          color: $gs-000;
        }
      }
      &:hover {
        background-color: rgba($primary-color, 0.2);
      }
      &:hover input {
        color: $primary-color;
      }
    }
  }

  .hidden {
    display: none;
  }

  .rotate180 {
    transform: rotate(180deg);
    transition: 0.3s;
  }
}
