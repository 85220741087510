@import "../../../style/main.scss";

.planAnalysisOuter {
  box-sizing: border-box;
  .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 22px;
    border-bottom: 1px solid $gs-002;
    .title {
      color: $primary-color;
      @include Noto-Sans-TCTitle243-Bold;
      .icon {
        font-size: 24px;
        margin-right: 12px;
      }
    }
    .close {
      width: 36px;
      height: 36px;
      border: 1px solid $gs-003;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      background: $gs-000;
      position: relative;
      cursor: pointer;
      .icon {
        @include Noto-Sans-TCFootnote142-Medium;
        color: $gs-006;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &:hover {
        background: $gs-002;
      }
      &:active {
        background: $gs-003;
        border: 1px solid $gs-004;
      }
    }
  }
  .body {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 611px;
    .planPercentage {
      width: 60%;
      padding: 18px 24px 0 0;
      .planHeader {
        display: flex;
        justify-content: space-between;
        .planTitle {
          @include Noto-Sans-TCCallout163-Bold;
        }
        .planDropdown {
          width: 150px;
        }
      }
    }
    .planBody {
      .chartOuter {
        height: 300px;
      }
      .tableOuter {
        table {
          width: 100%;
          thead {
            th {
              @include Noto-Sans-TCFootnote152-Medium;
              color: $gs-004;
              padding-bottom: 5px;
              border-bottom: 1px solid $gs-004;
              background: none;
              &:nth-child(2),
              &:nth-child(3) {
                padding-left: 0;
                padding-right: 0;
                text-align: center;
              }
            }
          }
          tbody {
            td {
              padding: 8px 0;
              color: $gs-008;
              @include Noto-Sans-TCFootnote142-Medium;
              &:nth-child(2),
              &:nth-child(3) {
                @include Montserrat-Semi-Bold;
                font-size: 14px;
                line-height: normal;
                text-align: center;
              }
              .dot {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                display: inline-block;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
    .planContent {
      overflow-y: scroll;
      padding: 8px 20px;
      width: 40%;
      background: $gs-000;
      border-left: 1px solid $gs-003;
      .content {
        color: $gs-007;
        @include Noto-Sans-TCFootnote141-Regular;
        margin-bottom: 5px;
      }
      .contentNoClick {
        @include Noto-Sans-TCTitle163-Bold;
        color: $gs-004;
        margin-bottom: 24px;
      }
      .contentClickTitle {
        @include Noto-Sans-TCTitle163-Bold;
        color: $primary-color;
        margin-bottom: 24px;
      }
      .contentTitle {
        @include Noto-Sans-TCCallout163-Bold;
        margin-bottom: 8px;
      }
    }
  }
}
