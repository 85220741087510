@import '../../../style/main.scss';

.tableContainer {
  width: 100%;
  padding-bottom: 36px;

  table {
    width: 100%;
  }
  .title {
    margin-top: 32px;
    margin-bottom: 16px;
    @include Noto-Sans-TCTitle163-Bold;
    color: $gs-009;
    width: 200px;
    display: block;
  }
  tr {
    th {
      margin-top: 16px;
      padding: 10px 16px;
      background: $system-pgrey;
      @include Noto-Sans-TCCaption131-Regular;
      color: $gs-006;
      &:nth-child(1) {
        border-radius: 10px 0px 0px 10px;
      }
      &:nth-child(2) {
        border-radius: 0px 10px 10px 0px;
      }
    }
  }
  td {
    color: $gs-008;
    padding: 14px 16px;
    &:nth-child(1) {
      @include Noto-Sans-TCCallout143-Bold;
    }
    &:nth-child(2) {
      @include Noto-Sans-TCFootnote141-Regular;
    }
  }
}
