@import '../../../../style/main.scss';

.PAEIcontainer {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: calc(100vw - 350px);
  height: 100vh;
  margin: 45px 32px 45px 64px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 13px;
    border-bottom: 1px solid $gs-002;

    .title {
      @include Noto-Sans-TCTitle243-Bold;
      color: #666666;
      line-height: 32px;
    }

    .scoreSetting {
      display: flex;
      align-items: center;

      .tick {
        color: $system-orange;
        @include Noto-Sans-TCFootnote141-Regular;
        margin-right: 16px;
        display: flex;
        align-items: center;

        span {
          padding-left: 10px;
        }

        .checkCircle {
          color: $system-green;
          width: 20px;
          height: 20px;
        }
      }
      .scoreSettingBtn {
        display: flex;

        .btn {
          margin: 0 6px;
        }
      }
    }
  }
  .body {
    margin-top: 32px;
    .listTitle {
      color: $gs-009;
      @include Noto-Sans-TCTitle163-Bold;
    }
    .tableContainer {
      width: 100%;
      table {
        width: 100%;
        margin-top: 11px;
        th {
          background: $system-pgrey;
          padding: 10px 16px;
          color: $gs-006;
          @include Noto-Sans-TCCaption131-Regular;
          &:nth-child(1) {
            border-radius: 10px 0px 0px 10px;
          }
          &:last-child {
            border-radius: 0px 10px 10px 0px;
          }
        }
        tr {
          td {
            padding: 12px 16px;
            .tdTitle {
              @include Noto-Sans-TCFootnote142-Medium;
              white-space: pre-line;
              textarea {
                height: 50px;
                background: $gs-001;
                border-radius: 8px;
                border: 1px solid $gs-002;
                padding: 5px 10px;
                margin-right: 10px;
                width: calc(100%);
                resize: none;
                @include Noto-Sans-TCFootnote142-Medium;
              }
            }
            .iconFlex {
              width: calc(100% + 16px);
              display: flex;
              justify-content: flex-end;
              .iconBg {
                position: relative;
                width: 34px;
                height: 36px;
                border: 1px solid $gs-003;
                background: #ffffff;
                border-radius: 8px;
                margin-bottom: 10px;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
                display: flex;
                justify-content: center;
                align-items: center;
                &:first-child {
                  margin-right: 5px;
                }
                &:last-child {
                  margin-left: 5px;
                }
                .icon {
                  width: 20px;
                  height: 20px;
                  color: $gs-006;
                  cursor: pointer;
                }
                .iconDone {
                  width: 20px;
                  height: 20px;
                  color: $system-green;
                  cursor: pointer;
                }
                .hoverStyle {
                  position: absolute;
                  top: 40px;
                  left: 0;
                  background: $gs-008;
                  border-radius: 6px;
                  opacity: 0.9;
                  color: $gs-000;
                  padding: 6px 8px;
                  width: 40px;
                  text-align: center;
                  @include Noto-Sans-TCCaption121-Regular;
                }
              }
            }
            .tdIcon {
              padding: 12px 16px;
              color: $primary-color;
              position: relative;
              cursor: pointer;
              .redDot {
                position: absolute;
                top: 6px;
                right: 10px;
                width: 4px;
                height: 4px;
                background: $system-red;
                border-radius: 50%;
              }
              .hoverStyle {
                border: 1px solid red;
              }
            }
          }
          .tdIcon {
            padding: 12px 16px;
            color: $primary-color;
            position: relative;
            cursor: pointer;
            .redDot {
              position: absolute;
              top: 6px;
              right: 10px;
              width: 4px;
              height: 4px;
              background: $system-red;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}
