@import '../../../style/main.scss';

.PAEIcontainer {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: calc(100vw - 350px);
  height: 100vh;
  margin: 45px 32px 45px 64px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      @include Noto-Sans-TCTitle243-Bold;
      color: #666666;
      line-height: 32px;
    }

    .scoreSetting {
      display: flex;
      align-items: center;

      .tick {
        color: $system-orange;
        @include Noto-Sans-TCFootnote141-Regular;
        margin-right: 16px;
        display: flex;
        align-items: center;

        span {
          padding-left: 10px;
        }

        .checkCircle {
          color: $system-green;
          width: 20px;
          height: 20px;
        }
      }
      .scoreSettingBtn {
        display: flex;

        .btn {
          margin: 0 6px;
          position: relative;
          .redDot {
            position: absolute;
            width: 5px;
            height: 5px;
            background: $system-red;
            border-radius: 50%;
            top: 6px;
            right: 24px;
          }
        }
      }
    }
  }

  .tabContainer {
    display: flex;
    margin-top: 30px;
    border-bottom: 1px solid $gs-002;
    .tab {
      cursor: pointer;
      padding: 14px 40px;
      color: $primary-color;
      @include Noto-Sans-TCCallout143-Bold;
      border-bottom: 2px solid $primary-color;
    }
  }
}
