@import "../../../../style/main.scss";

.checkboxInput {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.baseCheckbox {
  vertical-align: middle;
  font-size: 24px;
  cursor: pointer;
}

.isDisable {
  opacity: 0.5;
  cursor: not-allowed;
}
