@import '../../../style/main.scss';

.inner-layout {
  position: absolute;
  min-height: 100vh;
  left: 230px;
  width: calc(100% - 230px);
  box-sizing: border-box;
  overflow: auto;
  background-color: $gs-000;
  transition: 1s;
}

.inner-layout-show {
  position: absolute;
  min-height: 100vh;
  left: 0px;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  background-color: $gs-000;
  transition: 1s;
}

.dashboardRight {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  .dashboardTitle {
    margin: 40px 56px 29px 72px;
    padding-bottom: 29px;
    @include Montserrat-Semi-Bold;
    border-bottom: 1px solid $gs-003;
  }

  .dashboardSettings {
    @include Noto-Sans-TCFootnote141-Regular;
    margin: 20px 66px;

    .dashboardFlex {
      display: flex;

      .dashboardSettingsTitle {
        width: 170px;
        padding: 20px;
        color: $gs-006;
        font-size: 16px;
      }

      .dashboardSettingssContent {
        padding: 20px;
        font-size: 16px;
        display: flex;
      }

      .startService {
        padding-left: 10px;
      }

      .RadioMargin {
        margin-right: 20px;
      }
    }
  }
}

.dashboardBusinessRight {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  @include Montserrat-Semi-Bold;

  .tabsContainer {
    margin: 12px 56px 16px 72px;
  }

  .dashboardTitle {
    margin: 40px 56px 29px 72px;
    padding-bottom: 29px;
    @include Montserrat-Semi-Bold;
    border-bottom: 1px solid $gs-003;
  }

  .dashboardListContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 56px 20px 72px;

    .dashboardListTitle {
      @include Noto-Sans-TCTitle243-Bold;
      font-weight: 700;
      color: $gs-005;
    }

    .searchOutline {
      position: relative;

      input {
        width: 320px;
        line-height: 27px;
        padding: 10px 8px 10px 16px;
        border: 0;
        font-size: 16px;
        font-weight: 400;
        border-radius: 12px;

        &:disabled {
          background: #ccc;
          opacity: 50%;
        }
      }

      .BsSearch {
        display: inline-block;
        position: absolute;
        right: 5%;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }

  .tableContainer {
    margin: 16px 56px 24px 72px;

    table {
      width: 100%;

      th {
        color: $gs-006;
        font-size: 16px;
        border-bottom: 1px solid $gs-003;
        background-color: $system-table;
      }

      th,
      .tableTd {
        padding: 8px 16px;
        border-bottom: 1px solid $gs-003;
      }

      .tableTd {
        @include Montserrat-Semi-Bold;
        font-size: 16px;
        color: $gs-008;
      }

      .dropLists {
        cursor: pointer;
      }
    }

    .details {
      box-sizing: border-box;
      background-color: $gs-001;
      width: 100%;
      transition: all 1s ease-out;
      @include Montserrat-Semi-Bold;
      font-size: 16px;
      border-radius: 16px;

      .wrapContainer {
        display: flex;
        flex-wrap: wrap;
        align-content: center;

        .detailsListContainer {
          width: 40%;
          display: flex;
          align-items: center;
          padding: 16px 32px;
          // border-bottom: 1px solid $gs-003;
        }

        .detailsListTitle {
          width: 30%;
          @include Noto-Sans-TCBody161-Regular;
          color: $gs-007;
        }
      }
    }
  }

  .paginationContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 98px;
    @include Noto-Sans-TCFootnote141-Regular;
    color: $gs-006;
  }
}

.ServiceListClose {
  display: none;
}

.ServiceListOpen {
  display: flex;
  position: fixed;
  z-index: $zIndex-modal;
  left: 275px;
  top: 0;
  width: calc(100% - 275px);
  height: 100vh;
  background-color: $gs-002;

  .dashboardServiceList {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    @include Montserrat-Semi-Bold;

    .headerContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 40px 56px 29px 72px;
      padding-bottom: 29px;
      border-bottom: 1px solid $gs-003;

      .dashboardTitle {
        @include Montserrat-Semi-Bold;
      }
    }

    .dashboardListContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 16px 56px 15px 72px;

      .dashboardListTitle {
        @include Noto-Sans-TCTitle243-Bold;
        font-weight: 700;
        color: $gs-005;
      }

      .searchOutline {
        position: relative;

        input {
          width: 320px;
          line-height: 27px;
          padding: 10px 8px 10px 16px;
          border: 0;
          font-size: 16px;
          font-weight: 400;
          border-radius: 12px;
        }

        .BsSearch {
          display: inline-block;
          position: absolute;
          right: 5%;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }
    }

    .tableContainer {
      margin: 16px 56px 24px 72px;

      table {
        width: 100%;

        th {
          color: $gs-006;
          font-size: 16px;
          border-bottom: 1px solid $gs-003;
          background-color: $system-table;
        }

        th,
        .tableTd {
          padding: 8px 16px;
          border-bottom: 1px solid $gs-003;
        }

        .tableTd {
          @include Montserrat-Semi-Bold;
          font-size: 16px;
          color: $gs-008;
        }

        .dropLists {
          cursor: pointer;
        }
      }

      .details {
        box-sizing: border-box;
        background-color: $gs-001;
        width: 100%;
        transition: all 1s ease-out;
        @include Montserrat-Semi-Bold;
        font-size: 16px;
        border-radius: 16px;

        .wrapContainer {
          display: flex;
          flex-wrap: wrap;
          align-content: center;

          .detailsListContainer {
            width: 40%;
            display: flex;
            align-items: center;
            padding: 16px 32px;
            // border-bottom: 1px solid $gs-003;
          }

          .detailsListTitle {
            width: 30%;
            @include Noto-Sans-TCBody161-Regular;
            color: $gs-007;
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .inner-layout {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

@media (max-width: 1024px) {
  // .inner-layout {
  //   display: none;
  // }
  // .inner-layout-show {
  //   display: none;
  // }
}

@media (max-width: $media-xl) {
  // .inner-layout {
  //   left: 0;
  //   width: 100%;
  // }
}
