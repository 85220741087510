@import '../../../../style/main.scss';

.switch {
  position: relative;
  display: inline-block;
  width: 51px;
  height: 31px;
  -webkit-tap-highlight-color: transparent;
  &.small {
    height: 25px;
  }
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sliderTransparent,
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 51px;
  height: 31px;
  background-color: rgba($primary-color, 0.3);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 31px;
}

.sliderTransparent {
  background: rgba(255, 255, 255, 0.3);
}

.sliderTransparent::before,
.slider:before {
  position: absolute;
  content: '';
  height: 27px;
  width: 27px;
  left: 2px;
  bottom: 2px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: $primary-color;
}

input:checked + .sliderTransparent {
  background-color: rgba(14, 46, 84, 0.3);
}

input:focus + .sliderTransparent,
input:focus + .slider {
  box-shadow: 0 0 1px $gs-006;
}

input:checked + .sliderTransparent:before,
input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}
