@import '../../../style/main.scss';

.dashboardOuter {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: calc(100vw - 350px);
  height: 100vh;
  margin: 45px 32px 45px 64px;
}

.header {
  color: $gs-007;
  cursor: default;
  @include Noto-Sans-TCTitle243-Bold;
  margin-bottom: 13px;
}

.subHeader {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $gs-002;
  padding-bottom: 6px;

  .subHeaderLeft {
    width: 50%;
    display: flex;
  }
  .dropdownOuter {
    width: 182px;
    margin-right: 8px;
  }
}

.body {
  margin-top: 32px;
  .title {
    color: $gs-009;
    @include Noto-Sans-TCTitle163-Bold;
    margin-bottom: 10px;
  }

  .activate-0 {
    margin-left: -10px;
    width: 88px;
    height: 24px;
    background: $system-notActivateBg;
    border: 1px solid $system-notActivateBorder;
    border-radius: 8px;
    font-size: 13px;
    color: $system-red;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
  }

  .activate-1 {
    margin-left: -10px;
    width: 88px;
    height: 24px;
    background: $system-isActivateBg;
    border: 1px solid $system-isActivateBorder;
    border-radius: 8px;
    font-size: 13px;
    color: $system-green;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
  }
}
