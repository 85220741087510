@import '../../../../style/main.scss';

.textPurple {
  color: $primary-color;
}

.labelError {
  @include Noto-Sans-TCFootnote141-Regular;
  margin-left: 8px;
  color: $system-red;
  margin-top: 4px;
}

.messageError {
  @include Noto-Sans-TCFootnote141-Regular;
  margin-left: 8px !important;
  color: $system-error;
  margin-top: 4px;
  display: block;
}

.title {
  @include Noto-Sans-TCBody162-Medium;
  color: $primary-color;
  margin-bottom: 12px;
  margin-top: 24px;
}

.levelWrapper {
  display: flex;
  justify-content: center;
  strong {
    display: block;
    margin-top: 9px;
    @include Montserrat-Medium;
    font-size: 15px;
    letter-spacing: 1px;
    font-weight: 500;
  }
  span {
    margin-left: 32px;
    div {
      width: 175px;
    }
  }
}

.modalHeader {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  padding-top: 40px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 8px;
  position: relative;
  z-index: 2;
  .modalSubHeader {
    display: flex;
    justify-content: space-between;
  }
  .modalTitle {
    @include Noto-Sans-TCTitle243-Bold;
    color: $primary-color;
    display: flex;
    align-items: center;
    span {
      @include Noto-Sans-TCFootnote141-Regular;
      color: $gs-007;
      margin-left: 12px;
      margin-top: 10px;
    }
    .modalIcon {
      font-size: 42px;
      margin-right: 7px;
    }
  }
  .modalClose img {
    width: 35px;
    cursor: pointer;
  }
}

.modalBody {
  padding: 24px 32px;
  height: 70vh;
  overflow-y: scroll;
  background: $background-color;
  .tabContainer {
    margin-bottom: 8px;
  }
  .colHalf {
    display: flex;
    justify-content: flex-start;
    .inputWrapper {
      width: 49%;
      .labelTitle {
        color: $system-indigo;
        margin-bottom: 6px;
      }
      span {
        @include Noto-Sans-TCFootnote152-Medium;
        margin-top: 12px;
        display: block;
      }
    }
    .leftWrapper {
      width: 32.5%;
      .labelTitle {
        color: $system-indigo;
        margin-bottom: 6px;
      }
      span {
        @include Noto-Sans-TCFootnote152-Medium;
        margin-top: 12px;
        display: block;
      }
    }
    .rightWrapper {
      width: 65%;
      .labelTitle {
        color: $system-indigo;
        margin-bottom: 6px;
      }
      span {
        @include Noto-Sans-TCFootnote152-Medium;
        margin-top: 12px;
        display: block;
        &.messageError {
          @include Noto-Sans-TCFootnote141-Regular;
          margin-left: 8px;
          color: $system-error;
          margin-top: 4px;
        }
      }
    }
  }
  .colBig {
    width: 56%;
  }
  .colSmall {
    width: 40%;
  }
  .inputWrapper {
    width: 100%;
    margin-bottom: 25px;
  }
  label {
    @include Noto-Sans-TCFootnote142-Medium;
    margin-bottom: 5px;
    display: block;
  }
  .modalWrapper {
    @include Noto-Sans-TCCallout161-Regular;
    color: $gs-008;
    background-color: $gs-001;
    padding: 22px 22px 5px 22px;
    border-radius: 12px;
    margin-bottom: 24px;
    .icon {
      font-size: 20px;
      margin-top: 8px;
    }
    table {
      width: 100%;
      td {
        padding: 6px 4px;
        vertical-align: middle;
        .uploadOptions {
          display: flex;
          margin-bottom: 12px;
          width: 100%;
          .label {
            width: 90%;
            padding-top: 10px;
          }
        }
      }
    }
  }
  .modalBar {
    display: flex;
    margin-top: 24px;
    justify-content: space-between;
    .title {
      margin-top: 0;
    }
  }
  .actionArea {
    margin-top: 24px;
    border-top: 1px solid $gs-002;
    padding-top: 5px;
    text-align: center;
    position: relative;
    .removeWrapper {
      position: absolute;
      right: 0;
      top: 4px;
    }
  }
  .addButton {
    text-align: center;
    margin-bottom: 12px;
  }
}

.modalFooter {
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.04);
  padding: 18px 33px;
  display: flex;
  justify-content: flex-end;
}

.badge {
  @include Montserrat-Medium;
  border-radius: 20px;
  padding: 1px 8px;
  font-size: 13px;
  min-width: 30px;
  text-align: center;
  vertical-align: middle;
  color: $gs-000;
  margin: 0 2px;
  line-height: 19px;
  display: inline-block;
  &.green {
    background-color: $system-green;
  }
  &.blue {
    background-color: $system-teal;
  }
  &.yellow {
    background-color: $system-yellow;
  }
  &.orange {
    background-color: $system-orange;
  }
  &.red {
    background-color: $system-red;
  }
  &.purple {
    background-color: $system-purple;
  }
}

.tableSimple {
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
  @include Noto-Sans-TCCallout161-Regular;
}

thead {
  tr {
    th {
      @include Noto-Sans-TCFootnote151-Regular;
      background-color: $secondary-color;
      color: $gs-007;
      padding: 10px 16px;
      vertical-align: middle;
      // position: sticky;
      // top: 0;
      // overflow: visible;
      // z-index: 3;
      &:first-child {
        border-top-left-radius: 12px;
      }
      &:last-child {
        border-top-right-radius: 12px;
      }
    }
  }
}
tbody {
  tr {
    &.active {
      background-color: $gs-000;
      td {
        padding: 6px 5px;
        &.readOnly {
          padding: 6px 16px;
        }
      }
    }
  }
  td {
    @include Noto-Sans-TCCallout161-Regular;
    background-color: $gs-000;
    padding: 6px 16px;
    // border-top: 4px solid $background-color;
    vertical-align: middle;
    &.purple {
      color: $primary-color;
    }
    &.noPadding {
      padding: 0;
    }
    span {
      margin: 0 4px;
    }
    .icon {
      font-size: 22px;
      color: $primary-color;
      cursor: pointer;
    }
    &.editMode {
      padding: 6px 16px;
    }
    .editWrapper {
      position: relative;
    }
    .desc {
      display: inline;
    }
    .descBlue {
      display: inline;
      color: $tdp-blue;
    }
    .descGreen {
      display: inline;
      color: $tdp-green;
    }
    .btnEdit {
      margin-left: 6px;
      cursor: pointer;
      display: inline-block;
      position: absolute;
      margin-top: -1px;
      .icon {
        font-size: 18px;
      }
    }
    .modalEdit {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
      background: rgba(255, 255, 255, 0.9);
      box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.16);
      backdrop-filter: blur(30px);
      width: 320px;
      // height: 263px;
      z-index: 10;
      border-radius: 16px;
      padding: 24px;
      .modalTitle {
        margin-bottom: 24px;
        &.green {
          color: $tdp-green;
        }
        &.blue {
          color: $tdp-blue;
        }
      }
      .modalButton {
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .modalBtn {
          margin-left: 8px;
        }
      }
    }
  }
}

.tableDetail {
  background: $gs-001;
  border-radius: 16px;
  padding: 8px 16px;
  width: 100%;
  thead {
    th {
      padding: 12px 16px;
      border-bottom: none;
    }
  }
  tbody {
    td {
      padding: 16px;
      border-bottom: none;
      border-top: 1px solid $gs-003;
      &.editMode {
        padding: 6px 16px;
      }
    }
  }
  tfoot {
    td {
      border: none;
      padding-left: 0;
      &:nth-child(2) {
        text-align: right;
      }
    }
  }
}

@media (max-width: $media-xl) {
}

@media (max-width: $media-lg) {
}

@media (max-width: $media-sm) {
}
