@import '../../../../style/main.scss';

.maskClose {
  display: none;
}

.mask {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $zIndex-snackBar;
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.3);
  .iconContainer {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    width: 50px;
    height: 50px;
    margin: auto;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    .icon {
      font-size: 40px;
      color: $system-red;
    }
  }

  .alertContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: $zIndex-snackBar;
    .customizeBar {
      transition: all 0.5s ease;
      box-sizing: border-box;
      padding: 24px;
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
      border-radius: 20px;
      min-height: 200px;
      background-color: $gs-000;
      width: 320px;
      .content {
        text-align: center;
        margin-bottom: 24px;
        h4 {
          @include Noto-Sans-TCTitle163-Bold;
          color: $gs-008;
          padding-bottom: 2px;
        }
        .warningType {
          color: $system-red;
        }
        p {
          @include Noto-Sans-TCCaption131-Regular;
          color: $gs-006;
        }
      }
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    button {
      width: 48%;
    }
    div {
      margin-left: 8px;
      display: block;
      width: 48%;
      button {
        width: 100%;
      }
    }
  }
}
