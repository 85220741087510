@import "../../../../style/main.scss";

// .searchSection {
//   background-color: $gs-001;
//   border: 1px solid $gs-002;
//   border-radius: 8px;
//   padding: 6px 10px;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   width: 100%;
//   height: 23px;
//   color: $primary-color;
//   svg {
//     vertical-align: unset;
//     margin-bottom: -3px;
//     color: $secondary-color;
//   }
//   .searchButtonBox {
//     display: flex;
//   }
//   .searchButton {
//     width: 18px;
//     font-size: 18px;
//     cursor: pointer;
//     color: $primary-color;
//   }

//   .searchonClearBox {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     .searchClearButton {
//       color: $gs-006;
//       margin-right: 5px;
//       cursor: pointer;
//     }
//   }
//   input {
//     @include Noto-Sans-TCBody141-Regular;
//     border: 0px;
//     background-color: $gs-001;
//     font-size: 16px;
//     width: 100%;
//   }
//   ::placeholder {
//     color: $gs-004;
//     font-size: 15px;
//   }
// }

.searchSection {
  background-color: $gs-001;
  border: 1px solid $gs-002;
  border-radius: 8px;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 31px;
  color: $primary-color;
  svg {
    vertical-align: unset;
    margin-bottom: -3px;
    color: $primary-color;
  }
  .searchButtonBox {
    display: flex;
  }
  .searchButton {
    width: 18px;
    font-size: 18px;
    cursor: pointer;
  }

  .searchonClearBox {
    display: flex;
    justify-content: center;
    align-items: center;
    .searchClearButton {
      color: $gs-006;
      margin-right: 5px;
      cursor: pointer;
    }
  }
  input {
    @include Noto-Sans-TCBody141-Regular;
    border: 0px;
    background-color: $gs-001;
    font-size: 16px;
    width: 100%;
  }
  ::placeholder {
    color: $gs-004;
    font-size: 15px;
  }
}
