@import "./reset.scss";
@import "./color.scss";
@import "./font.scss";
@import "./mediaQuery.scss";
@import "./zIndex.scss";

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background: $gs-003;
}
::-webkit-scrollbar-track {
  -webkit-border-radius: 1px;
  border-radius: 1px;
  margin: 5px 0 5px 0;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 1px;
  border-radius: 1px;
  background: $gs-006;
}

%rightBarPadding {
  padding: 0 20px;
  border-bottom: 1px solid $gs-002;
}

.searchInput {
  width: 320px;
  line-height: 27px;
  padding: 10px 8px 10px 16px;
  border: 0;
  font-size: 16px;
  font-weight: 400;
  border-radius: 12px;
  background: $system-pgrey;
}

#notification-wrapper {
  pointer-events: none;
  height: 100vh;
  width: 100%;
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
}

.react-colorful__pointer {
  width: 20px !important;
  height: 20px !important;
  border: 1px solid #fff !important;
}
