@import '../../../style/main.scss';

.formSubtitle {
  @include Noto-Sans-TCTitle163-Bold;
  color: $gs-008;
  padding-bottom: 2px;
}

.formGroup {
  @include Noto-Sans-TCTitle163-Bold;
  color: $gs-008;
  padding-bottom: 8px;
  margin-top: 20px;
  font-weight: 500;
  font-size: 15px;
  text-align: left;
}

.error {
  @include Noto-Sans-TCTitle163-Bold;
  color: $gs-008;
  margin-top: 5px;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  color: $system-red;
}

.basic {
  text-transform: capitalize;
  display: inline-flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--Primary-Blue, #005eff);
  color: #fff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.2px;
}
.pro {
  text-transform: capitalize;
  display: inline-flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--Primary-Pro, #ff7020);
  color: #fff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.2px;
}
.free {
  text-transform: capitalize;
  display: inline-flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: $primary-color;
  color: #fff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.2px;
}

.flex {
  display: flex;
  align-items: center;
  .emailVerified {
    color: $tdp-green;
    margin-right: 5px;
    font-size: 22px;
    margin-top: 5px;
  }
}

.actionWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 7px;
  .btnWrapper {
    margin-right: 15px;
  }
}

.tagGroup {
  @include Montserrat-Semi-Bold;
  background-color: $primary-color;
  border-radius: 8px;
  color: $gs-000;
  width: fit-content;
  display: inline-block;
  padding: 4px 6px;
  margin: 0 3px;
  text-transform: uppercase;
  @include Montserrat-Medium;
  font-size: 13px;
  font-weight: 500;
}

.dropdownWrapper {
  width: 205px;
  margin-right: 10px;
}

.dashboardRight {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  .dashboardTitle {
    margin: 40px 56px 29px 72px;
    padding-bottom: 29px;
    @include Montserrat-Semi-Bold;
    border-bottom: 1px solid $gs-003;
  }
  .dashboardSettings {
    @include Noto-Sans-TCFootnote141-Regular;
    margin: 20px 66px;
    .dashboardFlex {
      display: flex;
      .dashboardSettingsTitle {
        width: 170px;
        padding: 20px;
        color: $gs-006;
        font-size: 16px;
        display: flex;
        align-items: center;
      }
      .dashboardSettingssContent {
        padding: 20px;
        font-size: 16px;
        display: flex;
        .wrapper {
          display: flex;
          align-items: center;
        }
        .buttonWrapper {
          margin-left: 16px;
        }
      }
      .startService {
        padding-left: 10px;
      }
      .RadioMargin {
        margin-right: 20px;
      }
    }
  }
}
.representativeNonSettingOuter {
  display: flex;
  align-items: center;
  .nonSettingTitle {
    @include Noto-Sans-TCCallout161-Regular;
    margin-right: 16px;
  }
}

.representativeUserDetail {
  @include Montserrat-Medium;
  font-size: 16px;
  font-weight: 500;
  button {
    &:first-child {
      margin-left: 16px;
    }
  }
}

.userSettingInputOuter {
  position: relative;
  .errorMsg {
    @include Noto-Sans-TCCallout141-Regular;
    color: $system-error;
  }
  .btnGroup {
    position: absolute;
    top: 43px;
    right: 0;
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    button {
      &:first-child {
        margin-right: 5px;
      }
    }
  }
}

.dashboardBusinessRight {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  @include Montserrat-Semi-Bold;

  .tabsContainer {
    margin: 12px 56px 16px 72px;
  }

  .dashboardTitle {
    margin: 40px 56px 29px 72px;
    padding-bottom: 29px;
    @include Montserrat-Semi-Bold;
    border-bottom: 1px solid $gs-003;
  }

  .dashboardListContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 56px 20px 72px;

    .searchBar {
      display: flex;
    }

    .dashboardListTitle {
      @include Noto-Sans-TCTitle243-Bold;
      font-weight: 700;
      color: $gs-005;
    }

    .searchOutline {
      position: relative;
      margin-left: 12px;
      // background: $system-pgrey;

      input {
        width: 320px;
        line-height: 27px;
        padding: 10px 8px 10px 16px;
        border: 0;
        font-size: 16px;
        font-weight: 400;
        border-radius: 12px;
        background: $system-pgrey;

        &:disabled {
          background: #ccc;
          opacity: 50%;
        }
      }

      .BsSearch {
        display: inline-block;
        position: absolute;
        right: 5%;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }

  .tableContainer {
    margin: 16px 56px 24px 72px;
    .lodingContent {
      width: 100%;
      height: 40%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    table {
      width: 100%;

      th {
        @include Noto-Sans-TCBody161-Regular;
        color: $gs-006;
        font-size: 16px;
        border-bottom: 1px solid $gs-003;
        background-color: $system-table;
        &.center {
          text-align: center;
        }
      }

      th,
      td {
        padding: 8px 16px;
        border-bottom: 1px solid $gs-003;
        &.center {
          text-align: center;
        }
      }

      .tableTd {
        @include Noto-Sans-TCFootnote152-Medium;
        font-size: 16px;
        color: $gs-008;
        .monsterat {
          @include Montserrat-Medium;
          font-size: 15px;
          font-weight: 500;
        }
      }

      .dropLists {
        cursor: pointer;
      }
    }

    .details {
      box-sizing: border-box;
      background-color: $gs-001;
      width: 100%;
      transition: all 1s ease-out;
      @include Montserrat-Semi-Bold;
      font-size: 16px;
      border-radius: 16px;
      .wrapContainer {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        .detailsListContainer {
          width: 45%;
          display: flex;
          align-items: center;
          padding: 16px 16px;
          &.first {
            align-items: flex-start;
          }
          small {
            font-size: 13px;
          }
        }
        .detailsListTitle {
          width: 30%;
          @include Noto-Sans-TCBody161-Regular;
          color: $gs-007;
        }
      }
    }
  }

  .paginationContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 98px;
    @include Noto-Sans-TCFootnote141-Regular;
    color: $gs-006;
    .pageDetail {
      width: 33.3%;
    }
  }
}

.ServiceListClose {
  display: none;
}

.ServiceListOpen {
  display: flex;
  position: fixed;
  z-index: $zIndex-topNavigation;
  left: 275px;
  top: 0;
  width: calc(100% - 275px);
  height: 100vh;
  background-color: $gs-002;
  transition: 0.5s;
}
.ServiceListOpenNoInner {
  display: flex;
  position: fixed;
  z-index: $zIndex-modal;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: $gs-002;
  transition: 0.5s;
}
.dashboardServiceList {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  @include Montserrat-Semi-Bold;
  background-color: $gs-000;
  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 56px 29px 72px;
    padding-bottom: 29px;
    border-bottom: 1px solid $gs-003;
    .dashboardTitle {
      @include Montserrat-Semi-Bold;
    }
  }

  .dashboardListContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 56px 15px 72px;

    .dashboardListTitle {
      @include Noto-Sans-TCTitle243-Bold;
      font-weight: 700;
      color: $gs-005;
    }

    .searchOutline {
      position: relative;
      // background: $system-pgrey;

      input {
        width: 320px;
        line-height: 27px;
        padding: 10px 8px 10px 16px;
        border: 0;
        font-size: 16px;
        font-weight: 400;
        border-radius: 12px;
        background: $system-pgrey;
      }

      .BsSearch {
        display: inline-block;
        position: absolute;
        right: 5%;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }

  .tableContainer {
    margin: 16px 56px 24px 72px;

    table {
      width: 100%;

      th {
        color: $gs-006;
        font-size: 16px;
        border-bottom: 1px solid $gs-003;
        background-color: $system-table;
      }

      th,
      .tableTd {
        padding: 8px 16px;
        border-bottom: 1px solid $gs-003;
      }

      .tableTd {
        @include Montserrat-Semi-Bold;
        font-size: 16px;
        color: $gs-008;
      }

      .dropLists {
        cursor: pointer;
      }
    }

    .details {
      box-sizing: border-box;
      background-color: $gs-001;
      width: 100%;
      transition: all 1s ease-out;
      @include Montserrat-Semi-Bold;
      font-size: 16px;
      border-radius: 16px;
      .wrapContainer {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        .detailsListContainer {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 16px 32px;

          &.half {
            width: 42.5%;
          }
        }
        .detailsListTitle {
          min-width: 100px;
          @include Noto-Sans-TCBody161-Regular;
          color: $gs-007;
          margin-right: 10px;
        }
      }
    }
  }
}

.dashboardWorkspace {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  @include Montserrat-Semi-Bold;

  .tabsContainer {
    margin: 12px 56px 16px 72px;
  }

  .dashboardTitle {
    margin: 40px 56px 29px 72px;
    padding-bottom: 29px;
    @include Montserrat-Semi-Bold;
    border-bottom: 1px solid $gs-003;
  }

  .dashboardListContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 56px 20px 72px;

    .dashboardListTitle {
      @include Noto-Sans-TCTitle243-Bold;
      font-weight: 700;
      color: $gs-005;
    }
  }
  .searchBar {
    display: flex;
    justify-content: space-between;
    margin: 16px 56px 20px 72px;
    .searchOutline {
      position: relative;
      width: 344px;
      // background: $system-pgrey;

      input {
        width: 320px;
        line-height: 27px;
        padding: 10px 8px 10px 16px;
        border: 0;
        font-size: 16px;
        font-weight: 400;
        border-radius: 12px;
        background: $system-pgrey;

        &:disabled {
          background: #ccc;
          opacity: 50%;
        }
      }

      .BsSearch {
        display: inline-block;
        position: absolute;
        right: 4%;
        top: 50%;
        transform: translateY(-40%);
        cursor: pointer;
      }
    }
  }

  .mdRelative {
    position: relative;
    height: 200px;
    .ModalTitle {
      @include Montserrat-Bold;
      font-size: 16px;
    }
    .ModalName {
      @include Noto-Sans-TCBody142-Medium;
      span {
        color: $gs-005;
      }
    }
    .Modalinput {
      background-color: $gs-002;
      padding: 10px;
      width: 90%;
      border: 0;
      line-height: 27px;
      margin-top: 10px;
      border-radius: 12px;
    }
    .confirmDisabled {
      @include Noto-Sans-TCBody142-Medium;
      color: $system-red;
    }
    .btnContainer {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      bottom: 0;
      right: 0;
      .btnflex {
        width: 10px;
      }
    }
  }

  .tableContainer {
    margin: 16px 56px 24px 72px;

    table {
      width: 100%;

      th {
        color: $gs-006;
        font-size: 16px;
        border-bottom: 1px solid $gs-003;
        background-color: $system-table;
      }

      th,
      .tableTd {
        padding: 8px 16px;
        border-bottom: 1px solid $gs-003;
      }

      .tableTd {
        @include Montserrat-Semi-Bold;
        font-size: 16px;
        color: $gs-008;
      }

      .dropLists {
        cursor: pointer;
      }
    }

    .details {
      box-sizing: border-box;
      background-color: $gs-001;
      width: 100%;
      transition: all 1s ease-out;
      @include Montserrat-Semi-Bold;
      font-size: 16px;
      border-radius: 16px;
      .wrapContainer {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        .detailsListContainer {
          width: 40%;
          display: flex;
          align-items: center;
          padding: 16px 32px;
        }
        .detailsListTitle {
          width: 30%;
          @include Noto-Sans-TCBody161-Regular;
          color: $gs-007;
        }
      }
    }
  }

  .paginationContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 98px;
    @include Noto-Sans-TCFootnote141-Regular;
    color: $gs-006;
  }
}

.workspaceListClose {
  display: none;
}

.workspaceListOpen {
  display: block;
  z-index: 48;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: $gs-000;
  .ws {
    @include Montserrat-Semi-Bold;
    .titleContainer {
      margin: 40px 0px 29px 0px;
      padding-bottom: 15px;
      border-bottom: 1px solid $gs-003;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .dashboardTitle {
        @include Montserrat-Semi-Bold;
        border-bottom: 0;
        margin: 0;
        padding: 0;
      }
      .headerRightContainer {
        display: flex;
        align-items: center;
        @include Noto-Sans-TCFootnote142-Medium;
      }
      .mright {
        margin-right: 30px;
        cursor: pointer;
        display: flex;
        align-items: center;
      }
      .iconMargin {
        margin-left: 7px;
      }
    }

    .dashboardListContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 16px 0px 20px 0px;

      .dashboardListTitle {
        @include Noto-Sans-TCTitle243-Bold;
        font-weight: 700;
        color: $gs-005;
      }
    }
    .searchBar {
      display: flex;
      justify-content: space-between;
      margin: 16px 0px 20px 0px;
      .searchOutline {
        position: relative;
        width: 344px;
        // background: $system-pgrey;

        input {
          width: 320px;
          line-height: 27px;
          padding: 10px 8px 10px 16px;
          border: 0;
          font-size: 16px;
          font-weight: 400;
          border-radius: 12px;
          background: $system-pgrey;

          &:disabled {
            background: #ccc;
            opacity: 50%;
          }
        }

        .BsSearch {
          // border: 1px solid orange;
          display: inline-block;
          position: absolute;
          right: 4%;
          top: 50%;
          transform: translateY(-40%);
          cursor: pointer;
        }
      }
    }

    margin: 16px 56px 24px 72px;

    table {
      width: 100%;

      th {
        color: $gs-006;
        font-size: 16px;
        border-bottom: 1px solid $gs-003;
        background-color: $system-table;
      }

      th,
      .tableTd {
        padding: 8px 16px;
        border-bottom: 1px solid $gs-003;
      }

      .tableTd {
        @include Montserrat-Semi-Bold;
        font-size: 16px;
        color: $gs-008;
      }

      .dropLists {
        cursor: pointer;
      }
    }

    .details {
      box-sizing: border-box;
      background-color: $gs-001;
      width: 100%;
      transition: all 1s ease-out;
      @include Montserrat-Semi-Bold;
      font-size: 16px;
      border-radius: 16px;
      .wrapContainer {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        .detailsListContainer {
          width: 40%;
          display: flex;
          align-items: center;
          padding: 16px 32px;
          // border-bottom: 1px solid $gs-003;
        }
        .detailsListTitle {
          width: 30%;
          @include Noto-Sans-TCBody161-Regular;
          color: $gs-007;
        }
      }
    }
    .paginationContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 12px;
      padding-bottom: 98px;
      @include Noto-Sans-TCFootnote141-Regular;
      color: $gs-006;
    }
  }
}

.modal {
  display: none;
}

.modalOpen {
  display: flex;
  position: fixed;
  justify-content: flex-end;
  align-items: center;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
}

/* Modal Content/Box */
.modalContentLg {
  box-sizing: border-box;
  background-color: #fefefe;
  padding: 48px;
  border-radius: 16px;
  width: 75%;
  height: 100%;
  .headerContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  .headerTitle {
    @include Noto-Sans-TCTitle283-Bold;
  }
  .headerContent {
    @include Noto-Sans-TCCallout141-Regular;
    color: $gs-009;
  }
  .assignContent {
    @include Noto-Sans-TCFootnote151-Regular;
    color: $gs-007;
    margin-bottom: 12px;
  }
  .listTitleContainer {
    display: flex;
    justify-content: space-between;
    .listTitle {
      @include Noto-Sans-TCTitle163-Bold;
    }
    .listAssigned {
      @include Noto-Sans-TCTitle203-Bold;
    }
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .searchBar {
      display: flex;
      justify-content: space-between;
      margin: 16px 0px 20px 0px;
      .searchOutline {
        position: relative;
        width: 344px;
        // background: $system-pgrey;

        input {
          width: 320px;
          line-height: 27px;
          padding: 10px 8px 10px 16px;
          border: 0;
          font-size: 16px;
          font-weight: 400;
          border-radius: 12px;
          background: $system-pgrey;

          &:disabled {
            background: #ccc;
            opacity: 50%;
          }
        }

        .BsSearch {
          // border: 1px solid red;
          display: inline-block;
          position: absolute;
          right: 5%;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }
    }
    .tabcontainer {
      width: 30%;
    }
  }

  .tableContainer {
    margin: 16px 0px 24px 0px;
    height: calc(100vh - 350px);
    overflow-y: scroll;
    overflow-x: hidden;

    table {
      width: 100%;

      th {
        color: $gs-006;
        font-size: 16px;
        border-bottom: 1px solid $gs-003;
        background-color: $system-table;
      }

      th,
      .tableTd {
        padding: 8px 16px;
        border-bottom: 1px solid $gs-003;
      }

      .tableTd {
        @include Montserrat-Semi-Bold;
        font-size: 16px;
        color: $gs-008;
      }

      .dropLists {
        cursor: pointer;
      }
    }

    .details {
      box-sizing: border-box;
      background-color: $gs-001;
      width: 100%;
      transition: all 1s ease-out;
      @include Montserrat-Semi-Bold;
      font-size: 16px;
      border-radius: 16px;
      .wrapContainer {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        .detailsListContainer {
          width: 40%;
          display: flex;
          align-items: center;
          padding: 16px 32px;
          // border-bottom: 1px solid $gs-003;
        }
        .detailsListTitle {
          width: 30%;
          @include Noto-Sans-TCBody161-Regular;
          color: $gs-007;
        }
      }
    }
  }
  .fixedContainer {
    width: 75%;
    position: fixed;
    z-index: 9999;
    display: flex;
    justify-content: space-between;
    left: 25%;
    bottom: 0;
    padding: 25px 58px;
    align-items: center;
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 0px 0px 16px 16px;
    .btnContainer {
      display: flex;
      .mr {
        margin-right: 8px;
      }
    }
    .listCount {
      @include Montserrat-Semi-Bold;
      font-size: 16px;
      color: $gs-006;
    }
  }
}

@media (min-width: 1024px) {
  .inner-layout {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

@media (max-width: $media-xl) {
  .inner-layout {
    left: 0;
    width: 100%;
  }
}
