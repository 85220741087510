@import "../../../../style/main.scss";

.modalContainer {
  height: 700px;
  .header {
    padding-bottom: 16px;
    border-bottom: 1px solid $gs-002;
    margin-bottom: 16px;

    .title {
      @include Noto-Sans-TCTitle243-Bold;
      color: $primary-color;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .closeIcon {
        margin-right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        border: 1px solid $gs-004;
        border-radius: 12px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
        cursor: pointer;
        .icon {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .body {
    height: calc(700px - 66px);
    .bg {
      border-radius: 12px;
      padding: 21px 52px;
      background: $gs-002;
      height: 600px;
    }
    .testWrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .testBlock {
        width: calc(50% - 18px);
        display: grid;
        grid-template-columns: 32% 32% 32%;
        grid-auto-rows: 64px;
        grid-gap: 9px 8px;
        position: relative;
        &.firstBlock {
          padding-right: 15px;
          padding-bottom: 15px;
          border-right: 2px dashed $gs-004;
          border-bottom: 2px dashed $gs-004;
        }
        &.secondBlock {
          padding-left: 15px;
          padding-bottom: 15px;
          border-bottom: 2px dashed $gs-004;
          justify-content: flex-end;
        }
        &.thirdBlock {
          padding-right: 15px;
          padding-top: 15px;
          border-right: 2px dashed $gs-004;
        }
        &.lastBlock {
          padding-left: 15px;
          padding-top: 15px;
          justify-content: flex-end;
        }
        .bgTitle {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          @include Montserrat-Bold;
          font-size: 128px;
          color: $gs-003;
          opacity: 0.7;
        }
        .unitBlock {
          width: 144px;
          width: 100%;
          height: 64px;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: $gs-000;
          cursor: pointer;
          &.unitChecked {
            background-color: $gs-003;
          }
          .title {
            @include Noto-Sans-TCCallout162-Medium;
            color: $gs-009;
            z-index: 99;
          }
          .subtitle {
            @include Montserrat-Semi-Bold;
            color: $gs-005;
            font-size: 14px;
            z-index: 99;
            &.checked {
              color: $gs-007;
            }
          }
        }
      }
    }
  }
}
