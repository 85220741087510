@import '../../../style/main.scss';

.authBox {
  min-height: 100vh;
  background-image: url('../../../assets/SubBackground.jpeg');
  background-position: center;
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: column;
  padding-left: calc(100% / 1024 * 80);
  padding-right: calc(100% / 1024 * 80);
  justify-content: center;
  align-items: center;
  line-height: 169%;
  letter-spacing: 0.07px;
  font-family: NotoSansTC, sans-serif;
  a {
    color: black;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }

  .logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      padding-top: 30px;
      padding-bottom: 30px;
      width: 180px;
    }
  }

  .formBox {
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
    .formContainer {
      flex: 1;
      display: flex;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(40px);
      border-radius: 32px;
      padding: 48px;

      .subTitle {
        font-size: 20px;
        letter-spacing: 0.07px;
        line-height: 27px;
        margin-bottom: 16px;
        color: $gs-008;
      }

      .formTitle {
        font-weight: 700;
        font-size: 24px;
        letter-spacing: 0.24px;
        line-height: 32px;
        margin-top: 8px;
        margin-bottom: 16px;
        color: $gs-008;
      }
      .titleTop {
        color: #0e2e54;
        font-weight: 700;
        line-height: normal;
        font-size: 53px;
        margin-top: 16px;
        font-family: NotoSansTC, sans-serif;
      }

      .titleBottom {
        color: #0e2e54;
        font-weight: 700;
        line-height: normal;
        font-size: 53px;
        margin-bottom: 8px;
        font-family: Montserrat, sans-serif;
      }

      .loginForm {
        overflow: auto;
        display: flex;
        flex-direction: column;
        width: 320px;
        max-height: 500px;
        padding-right: 16px;

        .inputSection {
          max-height: 300px;
          overflow-x: hidden;
          overflow-y: auto;
        }

        input {
          font-size: 16px;
          line-height: 27px;
          width: 100%;
          min-height: 48px;
          background: $gs-002;
          box-sizing: border-box;
          border-radius: 12px;
          color: $gs-008;
          padding: 10px 16px;
          margin-bottom: 12px;
        }
        ::placeholder {
          color: $gs-005;
          font-size: 15px;
        }
        input:disabled {
          color: $gs-005;
          opacity: 0.6;
        }
        select {
          font-size: 16px;
          line-height: 27px;
          width: 100%;
          min-height: 48px;
          background: transparent;
          border-radius: 12px;
          padding: 10px 16px;
          -webkit-appearance: none;
          appearance: none;
        }
        ::placeholder {
          color: $gs-005;
          font-size: 15px;
        }
        .emailExpired {
          padding-left: 16px;
          margin-top: 5px;
          margin-bottom: 12px;
          @include Noto-Sans-TCFootnote141-Regular;
          color: rgba(255, 59, 48, 0.8);
        }
        p {
          padding-left: 16px;
          margin-top: -8px;
          margin-bottom: 12px;
          @include Noto-Sans-TCFootnote141-Regular;
          color: rgba(255, 59, 48, 0.8);
        }
        .selectInput {
          background: $gs-002;
          box-sizing: border-box;
          border-radius: 12px;
          margin-bottom: 12px;
        }

        .submitContent {
          padding-top: 8px;
        }
        .noValueSelect {
          color: $gs-005;
          border: 0px;
        }

        .nonErrorInput {
          color: $gs-009;
          border: 0px;
        }

        .errorInput {
          border: 2px solid rgba(255, 59, 48, 0.5);
        }
      }

      .stepper {
        padding-top: 32px;
        padding-bottom: 16px;
        margin-top: 25px;
        width: fit-content;
        .stepperLine {
          margin-bottom: -25px;
          width: calc(100% - 36px);
          height: 4px;
          justify-content: space-between;
          background-color: rgba(255, 255, 255, 0.2);
        }

        .stepperCircle {
          margin-right: 36px;
          display: inline-block;
          padding: 4px;
          text-align: center;
          height: 24px;
          width: 24px;
          background-color: $gs-004;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
          color: $gs-007;
          font-weight: 700;
          font-family: Montserrat, sans-serif;
        }

        .stepperFinishedCircle {
          margin-right: 36px;
          display: inline-block;
          padding: 4px;
          text-align: center;
          height: 24px;
          width: 24px;
          background-color: $gs-004;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
          color: $gs-009;
          font-weight: 700;
          font-family: Montserrat, sans-serif;
        }

        .stepperActivationCircle {
          margin-right: 36px;
          display: inline-block;
          padding: 9px;
          text-align: center;
          height: 26px;
          width: 26px;
          background-color: #0e2e54;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
          border-radius: 50%;
          justify-content: center;
          align-items: center;
          color: white;
          font-weight: 700;
          font-family: Montserrat, sans-serif;
        }
      }

      .confirmContent {
        min-width: 320px;

        h2 {
          color: $gs-008;
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          padding-top: 12px;
          padding-bottom: 28px;
        }

        .checkMarkIcon {
          width: 72px;
          color: $gs-007;
          padding-bottom: 12px;
          padding-top: 32px;
        }
      }
    }
  }
  .tokenError {
    @include Noto-Sans-TCBody142-Medium;
    color: red;
  }

  .btnDefault {
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.07px;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
    border-radius: 12px;
    background-color: $gs-009;
    border: 1px solid $gs-009;
    color: white;
    margin-top: 12px;
    padding: 10px;
    font-weight: 700;
  }
  .btnIcon {
    display: flex;
    align-items: center;
    span {
      margin-right: 5px;
      margin-left: -24px;
    }
  }

  .btnDisabled {
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.07px;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
    border-radius: 12px;
    background-color: $gs-009;
    border: 1px solid $gs-009;
    color: white;
    margin-top: 12px;
    padding: 10px;
    font-weight: 700;
    opacity: 80%;
  }

  .btnOutline {
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.07px;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
    border-radius: 12px;
    background-color: white;
    border: 2px solid $gs-008;
    color: $gs-008;
    padding: 10px;
    margin-top: 12px;
    font-weight: 700;
  }

  .passwordContent {
    color: #333;
    font-size: 22px;
    position: relative;
    max-width: 100%;
    text-align: end;
    input {
      padding-right: 30px;
    }
    div {
      position: absolute;
      width: fit-content;
      top: 13px;
      right: 10px;
    }
  }
  .passwordAlert {
    @include Noto-Sans-TCFootnote142-Medium;
  }
  .rememberUserContent {
    display: inline-flex;
    margin-top: -4px;
    align-items: center;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.07px;
    font-weight: 400;
    h4 {
      padding-left: 12px;
    }

    .rememberUserText {
      color: $gs-009;
    }

    .rememberUserTextUnChecked {
      color: $gs-007;
    }
  }

  .errorContent {
    display: flex;
    color: rgba(255, 59, 48, 0.8);
    margin-top: 8px;
    align-items: center;
    margin-bottom: -8px;
    .errorIcon {
      font-size: 20px;
      align-items: center;
    }
    .errorMessage {
      align-items: center;
      h4 {
        @include Noto-Sans-TCFootnote141-Regular;
        margin-left: 8px;
      }
    }
    button {
      width: 130px;
      padding: 6px 12px 7px;
    }
  }

  .registerLinkContent {
    margin-top: 16px;
    max-width: 320px;
    display: flex;
    .divider {
      margin-left: 36px;
      margin-right: 36px;
      width: 1px;
      height: 24px;
      background-color: $gs-006;
    }
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .backLinkContent {
    padding-bottom: 9px;
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: flex-start;
  }
}

@media (max-width: $media-xxl) {
  .authBox {
    .formBox {
      .formContainer {
        padding: 32px 56px;
        width: 100%;
        .titleTop {
          font-size: 38px;
        }
        .titleBottom {
          font-size: 38px;
        }
        .subTitle {
          font-size: 16px;
        }
        .formTitle {
          font-size: 18px;
        }
        .stepper {
          padding-top: 24px;
          padding-bottom: 12px;
        }
        .loginForm {
          padding-right: 8px;
          margin-right: -8px;
        }
      }
      .confirmContent {
        h2 {
          font-size: 18px;
          line-height: 26px;
          padding-top: 0px;
          padding-bottom: 20px;
        }

        .checkMarkIcon {
          width: 48px;
          padding-bottom: 12px;
          padding-top: 56px;
        }
      }
    }
  }
}

@media (max-width: $media-xl) {
  .authBox {
    display: block;
    justify-content: center;
    .logoContainer {
      img {
        width: 104px;
      }
    }
    .formBox {
      justify-content: center;
      padding-top: 0px;
      padding-bottom: 0px;
      .formContainer {
        padding: 32px 56px;
        width: fit-content;
        flex: 0;
        justify-content: center;
        .loginForm {
          max-height: 400px;
          .inputSection {
            max-height: 250px;
            overflow-x: hidden;
            overflow-y: auto;
          }
        }
      }
    }
  }
}

@media (max-width: $media-lg) {
  .authBox {
    .logoContainer {
      img {
        width: 64px;
      }
    }
    .formBox {
      .formContainer {
        padding: 16px 20px;
        height: 566px;
        .loginForm {
          max-height: 380px;
        }
      }
    }
  }
}

@media (max-width: $media-sm) {
  .authBox {
    display: block;
    padding-left: 0px;
    padding-right: 0px;
    position: fixed;
    width: 100%;
    overflow: hidden;
    .logoContainer {
      img {
        width: 42px;
      }
      position: fixed;
      width: 100%;
      height: 10vh;
    }
    .formBox {
      display: block;
      width: 100%;
      height: 90vh;
      position: fixed;
      bottom: 0;
      .formContainer {
        input {
          width: 100%;
          padding-bottom: 8px;
        }
        height: 90vh;
        box-sizing: border-box;
        width: 100%;
        display: block;
        justify-content: center;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;

        .titleTop {
          font-size: 28px;
        }
        .titleBottom {
          font-size: 28px;
        }

        .formTitle {
          font-size: 16px;
        }

        .stepper {
          padding-top: 16px;
          padding-bottom: 6px;
        }
        .loginForm {
          max-height: 350px;
          .inputSection {
            max-height: 250px;
            overflow-x: hidden;
            overflow-y: auto;
          }

          .submitContent {
            padding-bottom: 32px;
          }
        }
        .confirmContent {
          .checkMarkIcon {
            padding-top: 40px;
          }
        }
      }
    }
  }
}
