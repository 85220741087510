@import '../../../style/main.scss';
.lifeCycleOuter {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: calc(100vw - 350px);
  height: 100vh;
  margin: 45px 32px 45px 64px;
}

.tabContainer {
  display: flex;
  margin-top: 30px;
  border-bottom: 1px solid $gs-002;
  align-items: center;
  .tabLine {
    margin: 2px 10px;
    width: 2px;
    height: 20px;
    background: $gs-003;
  }
  .dropDowntab {
    width: 120px;
    margin-bottom: -12px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    @include Noto-Sans-TCTitle243-Bold;
    color: #666666;
    line-height: 32px;
  }

  .scoreSetting {
    display: flex;
    align-items: center;

    .tick {
      color: $system-orange;
      @include Noto-Sans-TCFootnote141-Regular;
      margin-right: 16px;
      display: flex;
      align-items: center;

      span {
        padding-left: 10px;
      }

      .checkCircle {
        color: $system-green;
        width: 20px;
        height: 20px;
      }
    }
    .scoreSettingBtn {
      position: relative;
      .redDot {
        position: absolute;
        width: 5px;
        height: 5px;
        background: $system-red;
        border-radius: 50%;
        top: 5px;
        right: 5px;
      }
    }
  }
}
.table {
  margin-top: 32px;
  width: 100%;

  .tableTitle {
    @include Noto-Sans-TCTitle163-Bold;
    color: $gs-009;
    margin-bottom: 14px;
    margin-left: 14px;
  }
  table {
    width: 100%;
    border-radius: 10px;

    th {
      background: $system-pgrey;
      padding: 10px 16px;
      color: #999999;
      @include Noto-Sans-TCCaption131-Regular;
      &:nth-child(1) {
        border-radius: 10px 0px 0px 10px;
      }
      &:last-child {
        border-radius: 0px 10px 10px 0px;
      }
    }

    tr {
      background: $gs-000;
      td {
        padding: 14px 16px;
        color: $gs-008;
        @include Noto-Sans-TCCaption132-Medium;
        .iconContainer {
          display: inline;
          position: relative;
          margin-right: 10px;
          cursor: pointer;
          .icon {
            width: 16px;
            height: 19px;
            color: $primary-color;
            margin-right: 30px;
          }
          .redDot {
            position: absolute;
            top: -8px;
            right: 0px;
            height: 4px;
            width: 4px;
            background-color: $system-red;
            border-radius: 50%;
          }
          .tip {
            position: absolute;
            width: 80px;
            top: 15px;
            left: 0;
            background-color: $gs-008;
            border-radius: 8px;
            padding: 6px 8px;
            color: $gs-000;
            z-index: $zIndex-button;
            text-align: center;
            @include Noto-Sans-TCCaption121-Regular;
          }
        }
      }
    }
  }
}

.scoreModal {
  .header {
    padding-bottom: 8px;
    border-bottom: 1px solid $gs-002;
    display: flex;
    justify-content: space-between;

    .title {
      @include Noto-Sans-TCTitle163-Bold;
      color: $gs-008;
    }
    .info {
      .icon {
        margin-right: 10px;
      }
      color: #ff9500;
      display: flex;
      align-items: center;
      @include Noto-Sans-TCFootnote141-Regular;
    }
  }
  .body {
    margin-top: 8px;
    margin-bottom: 8px;

    .tableHead {
      display: flex;
      margin-bottom: 5px;
      .title:nth-child(1) {
        width: 50%;
        background-color: $system-pgrey;
        padding: 10px 16px;
        @include Noto-Sans-TCCaption131-Regular;
        color: $gs-006;
        border-radius: 10px 0px 0px 10px;
      }
      .title:nth-child(2) {
        width: 50%;
        background-color: $system-pgrey;
        padding: 10px 16px;
        @include Noto-Sans-TCCaption131-Regular;
        color: $gs-006;
        border-radius: 0px 10px 10px 0px;
      }
    }
    .tableBody {
      display: flex;
      .index {
        width: 50%;
        padding: 14px 16px;
        color: $gs-008;
        @include Noto-Sans-TCFootnote141-Regular;
      }
      .dropdown {
        width: 50%;
      }
    }
    .footer {
      display: flex;
      justify-content: flex-end;
      & button:nth-child(1) {
        margin-right: 4px;
      }
    }
  }
}

.literatureModal {
  .header {
    padding-bottom: 16px;
    border-bottom: 1px solid $gs-002;
    margin-bottom: 16px;

    .title {
      @include Noto-Sans-TCTitle243-Bold;
      color: $primary-color;
      display: flex;
      align-items: center;
      .icon {
        margin-right: 16px;
      }
    }
  }
  .body {
    height: 550px;
    overflow-y: scroll;
    .block {
      margin-top: 24px;
      .inputTitle {
        @include Noto-Sans-TCCaption131-Regular;
        color: $gs-008;
      }
      .smInput {
        width: 40%;
        padding: 6px 10px;
        margin-top: 8px;
        background: $gs-002;
        border: 0;
        @include Noto-Sans-TCCallout141-Regular;
        line-height: 21px;
        border-radius: 8px;
      }
      .smErrorInput {
        width: 40%;
        padding: 6px 10px;
        margin-top: 8px;
        background: $gs-002;
        @include Noto-Sans-TCCallout141-Regular;
        line-height: 21px;
        border-radius: 8px;
        border: 1px solid #ff0000;
      }
      .ErrorText {
        color: red;
        @include Noto-Sans-TCCallout141-Regular;
        display: block;
      }
      .lgInput {
        width: 864px;
        height: 104px;
        padding: 6px 10px;
        margin-top: 8px;
        background: $gs-002;
        border: 0;
        @include Noto-Sans-TCCallout141-Regular;
        line-height: 21px;
        border-radius: 8px;
        resize: none;
      }
      .lgErrorInput {
        width: 864px;
        height: 104px;
        padding: 6px 10px;
        margin-top: 8px;
        background: $gs-002;
        @include Noto-Sans-TCCallout141-Regular;
        line-height: 21px;
        border-radius: 8px;
        border: 1px solid #ff0000;
      }
    }
    .paeiAnalysis {
      margin-top: 24px;
      margin-bottom: 8px;
      .subtitle {
        @include Noto-Sans-TCCaption131-Regular;
        color: $gs-006;
      }
      .paei {
        color: $primary-color;
        @include Noto-Sans-TCCallout162-Medium;
        margin-bottom: 24px;
      }
      .combineAnalysis {
        .combineTitle {
          color: $gs-009;
          display: flex;
          align-items: center;
          margin-bottom: 24px;
          .title {
            @include Noto-Sans-TCFootnote152-Medium;
          }
          .icon {
            width: 17px;
            height: 17px;
            color: $gs-004;
            margin-left: 9px;
            margin-top: 5px;
          }
        }
        .combineTips {
          position: relative;
          &:hover .tooltips {
            @include Noto-Sans-TCCaption121-Regular;
            color: $gs-000;
            background: $gs-008;
            padding: 6px 8px;
            border-radius: 6px;
            position: absolute;
            top: 25px;
            left: 0;
            width: 265px;
          }
        }
      }
      .combineBody {
        display: flex;
        table {
          width: 50%;
          margin-right: 12px;
          margin-left: 12px;
          thead {
            tr {
              th {
                background-color: $system-pgrey;
                line-height: 19px;
                padding: 10px 12px;
                color: $gs-006;
                @include Noto-Sans-TCCaption131-Regular;
                &:nth-child(1) {
                  border-radius: 10px 0px 0px 10px;
                }
                &:last-child {
                  border-radius: 0px 10px 10px 0px;
                }
              }
            }
          }
          tbody {
            .if_paei {
              padding: 14px 16px;
              color: $gs-008;
              @include Noto-Sans-TCCaption132-Medium;
            }
            .perfectMatch {
              color: $system-indigo;
              @include Montserrat-Semi-Bold;
              font-size: 14px;
            }
            .sminput {
              background: $gs-001;
              border: 1px solid $gs-002;
              padding: 5.5px 10px;
              line-height: 19px;
              border-radius: 8px;
            }
            .sminputErr {
              background: $gs-001;
              padding: 5.5px 10px;
              line-height: 19px;
              border-radius: 8px;
              border: 1px solid #ff0000;
            }
            .ErrorText {
              color: red;
              @include Noto-Sans-TCCallout141-Regular;
              display: block;
            }
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
    & button:nth-child(1) {
      margin-right: 4px;
    }
  }
}
