@import '../../../style/main.scss';

.personalTalentOuter {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100vw - 350px);
  height: 100vh;
  margin: 45px 32px 45px 64px;
  box-sizing: border-box;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1px solid $gs-002;

    .title {
      @include Noto-Sans-TCTitle243-Bold;
      color: #666666;
      line-height: 32px;
    }
  }
  .layout {
    padding-top: 12px;
    .typeHeadOuter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .typeTitle {
        @include Noto-Sans-TCTitle163-Bold;
      }
      .penOuter {
        position: relative;
        cursor: pointer;
        .pen {
          color: $primary-color;
          width: 22px;
          height: 22px;
        }
        .redDot {
          position: absolute;
          top: 0;
          right: -8px;
          background: $system-red;
          width: 5px;
          height: 5px;
          border-radius: 50%;
        }
        .hoverStyle {
          width: 30px;
          text-align: center;
          position: absolute;
          padding: 6px 8px;
          left: 0;
          top: 37px;
          background: $gs-008;
          opacity: 0.9;
          @include Noto-Sans-TCCaption121-Regular;
          color: $gs-000;
          border-radius: 6px;
        }
      }
      .editModeOuter {
        display: flex;
      }
    }
    .squareBlock {
      margin-top: 103px;
      width: 100%;
      position: relative;
      .squareType {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        .squareOuter {
          position: relative;
          display: flex;
          align-items: center;
          margin-right: 30px;
          cursor: pointer;
          .square {
            width: 12px;
            height: 12px;
            border-radius: 3px;
            background: $gs-008;
            margin-right: 10px;
          }
          .typeName {
            @include Noto-Sans-TCFootnote141-Regular;
            color: $gs-007;
          }
          .hoverStyle {
            z-index: 5;
            position: absolute;
            width: 80px;
            top: 25px;
            left: 50%;
            transform: translateX(-50%);
            background: $gs-008;
            padding: 6px 8px;
            border-radius: 6px;
            opacity: 0.9;
            color: $gs-000;
            @include Noto-Sans-TCCaption121-Regular;
          }
        }
      }
      .wrapper {
        display: flex;
        .leftSide {
          display: inline-flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          border-right: 3px dashed $gs-002;
          height: 452px;
          width: 34px;
          padding-right: 4px;
          .yVariable {
            @include Montserrat-Semi-Bold;
            position: absolute;
            top: -14px;
            left: -4px;
            color: $primary-color;
            font-size: 18px;
            opacity: 1;
          }
          span {
            @include Montserrat-Medium;
            font-size: 13px;
            color: $gs-007;
            line-height: 19.5px;
            font-weight: 500;
            opacity: 50%;
          }
        }
        .main {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          .unitBorder {
            width: 33%;
            border-right: 3px dashed $gs-002;
            border-bottom: 3px dashed $gs-002;
            .unit {
              width: calc(100% - 42px);
              height: 140px;
              border-radius: 12px;
              margin: 4px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 0 16px 0 17px;
              position: relative;

              .title {
                @include Noto-Sans-TCTitle243-Bold;
                text-align: center;
                margin: auto;
                color: $gs-007;
                width: 250px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .subtitle {
                @include Noto-Sans-TCFootnote141-Regular;
                margin-top: 8px;
                text-align: center;
                color: $gs-007;
              }
              .hint {
                position: absolute;
                top: 90%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: calc(100% - 60px);
                background: rgba(255, 255, 255, 0.6);
                padding: 16px;
                border-radius: 12px;
                backdrop-filter: blur(8px);
                z-index: 22;
                .hintTitle {
                  color: $gs-008;
                  @include Noto-Sans-TCCaption132-Medium;
                  margin-bottom: 8px;
                }
                .hintSubtitle {
                  color: $gs-006;
                  @include Noto-Sans-TCCaption121-Regular;
                }
              }
              .editOuter {
                width: 100%;
                height: 100px;
                .inputTitle {
                  width: calc(100% - 6px);
                  display: block;
                  height: 28px;
                  background: $gs-002;
                  border: 1px solid $gs-003;
                  margin-bottom: 6px;
                  border-radius: 8px;
                  @include Noto-Sans-TCFootnote141-Regular;
                }
                .inputDescription {
                  display: block;
                  width: calc(100% - 7px);
                  background: $gs-002;
                  border: 1px solid $gs-003;
                  height: 60px;
                  border-radius: 8px;
                  resize: none;
                  @include Noto-Sans-TCFootnote141-Regular;
                }
              }
            }

            .unitSelected {
              width: calc(100% - 8px);
              height: 140px;
              border-radius: 12px;
              background-color: $gs-009;
              margin: 4px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 0 16px 0 17px;
              .title {
                @include Noto-Sans-TCTitle243-Bold;
                color: $gs-000;
              }
              .subtitle {
                @include Noto-Sans-TCFootnote141-Regular;
                margin-top: 8px;
                text-align: center;
                color: $gs-000;
              }
            }
          }
          .bottomSide {
            width: 100%;
            justify-content: space-around;
            position: relative;
            margin-top: 8px;
            height: 20px;
            display: inline-flex;
            align-items: center;
            .xVariable {
              @include Montserrat-Semi-Bold;
              position: absolute;
              right: 0;
              color: $primary-color;
              font-size: 18px;
              opacity: 1;
            }
            span {
              @include Montserrat-Medium;
              font-size: 13px;
              color: $gs-007;
              line-height: 19.5px;
              font-weight: 500;
              opacity: 50%;
            }
          }
        }
      }
    }
  }
}

.colorGreen {
  background: rgba(52, 199, 89, 0.3);
}
.colorYellow {
  background: rgba(255, 204, 0, 0.3);
}
.colorGrey {
  background: rgba(221, 221, 221, 0.3);
}
.colorDarkGrey {
  background: rgba(180, 180, 180, 0.3);
}
.colorPink {
  background: rgba(255, 45, 85, 0.3);
}
