@import '../../../../style/main.scss';

.textPurple {
  color: $primary-color;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: calc(100vw - 350px);
  height: 100vh;
  margin: 45px 32px 45px 64px;

  .searchOutline {
    position: relative;
    width: 228px;
    margin-top: -2px;
    input {
      width: 208px;
      line-height: 27px;
      padding: 10px 8px 10px 16px;
      border: 0;
      font-size: 16px;
      font-weight: 400;
      border-radius: 12px;
      &:disabled {
        background: #ccc;
        opacity: 50%;
      }
    }
    .BsSearch {
      display: inline-block;
      position: absolute;
      right: 4%;
      top: 50%;
      transform: translateY(-52.5%);
      cursor: pointer;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      @include Noto-Sans-TCTitle243-Bold;
      color: #666666;
      line-height: 32px;
    }

    .scoreSetting {
      display: flex;
      align-items: center;

      .tick {
        color: $system-orange;
        @include Noto-Sans-TCFootnote141-Regular;
        margin-right: 10px;
        display: flex;
        align-items: center;

        .icon {
          font-size: 20px;
          color: $system-orange;
        }

        span {
          padding-left: 8px;
        }

        .checkCircle {
          color: $system-green;
          width: 20px;
          height: 20px;
        }
      }
      .scoreSettingBtn {
        display: flex;

        .btn {
          margin: 0 6px;
          position: relative;
          .redDot {
            position: absolute;
            width: 5px;
            height: 5px;
            background: $system-red;
            border-radius: 50%;
            top: 6px;
            right: 24px;
          }
        }
      }
    }
  }

  .tabContainer {
    display: flex;
    margin-top: 30px;
    border-bottom: 1px solid $gs-002;
    justify-content: space-between;
    .tab {
      cursor: pointer;
      padding: 14px 40px;
      color: $primary-color;
      @include Noto-Sans-TCCallout143-Bold;
      border-bottom: 2px solid $primary-color;
    }
  }

  .tableContainer {
    .title {
      @include Noto-Sans-TCCallout162-Medium;
      margin-top: 32px;
    }

    table {
      margin-top: 16px;
      width: 100%;
      th {
        background-color: $system-table;
        padding: 10px 16px;
        color: $gs-006;
        @include Noto-Sans-TCCaption131-Regular;
        &:nth-child(1) {
          border-radius: 10px 0px 0px 10px;
          width: 25%;
        }
        &:last-child {
          border-radius: 0px 10px 10px 0px;
        }
      }
      tr {
        background: $gs-000;
        td {
          padding: 14px 16px;
          color: $gs-008;
          @include Noto-Sans-TCCaption132-Medium;
          .buttonWrapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            span {
              margin-right: 8px;
            }
          }
          .iconContainer {
            display: inline;
            position: relative;
            margin-right: 10px;
            cursor: pointer;
            .icon {
              width: 16px;
              height: 19px;
              color: $primary-color;
              margin-right: 30px;
            }
            .redDot {
              position: absolute;
              top: -8px;
              right: 0px;
              height: 4px;
              width: 4px;
              background-color: $system-red;
              border-radius: 50%;
            }
            .tip {
              position: absolute;
              width: 80px;
              top: 15px;
              left: 0;
              background-color: $gs-008;
              border-radius: 8px;
              padding: 6px 8px;
              color: $gs-000;
              z-index: $zIndex-button;
              text-align: center;
              @include Noto-Sans-TCCaption121-Regular;
            }
          }
        }
      }
      .emptybox {
        width: 100%;
      }
      .editModeTd {
        min-height: 173px;
        padding: 6px;
        position: relative;
        .textAreaOuter {
          background: $gs-001;
          z-index: 0;
          border-radius: 8px;
          .editMode {
            position: relative;
            border: 1px solid $gs-003;
            border-radius: 8px;
            background: transparent;
            height: 173px;
            width: 100%;
            resize: none;
            box-sizing: border-box;
            padding: 10px;
            @include Noto-Sans-TCBody141-Regular;
            z-index: 2;
          }
        }
        .textAeraIcon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #dddddd;
          width: 40px;
          height: 40px;
          z-index: 0;
        }
      }
      .nonEditdTd {
        min-height: 173px;
        padding: 6px;
        position: relative;
        .nonEditMode {
          border: 1px solid $gs-003;
          border-radius: 8px;
          height: 173px;
          width: 100%;
          resize: none;
          box-sizing: border-box;
          padding: 10px;
          @include Noto-Sans-TCBody141-Regular;
        }
      }
    }
  }
}
