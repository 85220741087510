@import "../../../../style/main.scss";

.radioBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .option {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    img {
      width: 24px;
      display: block;
      padding: 5px 0;
      margin-right: 8px;
      &.disable {
        filter: brightness(50%) invert(0.75);
        cursor: not-allowed;
      }
    }
    span {
      @include Noto-Sans-TCBody141-Regular;
      width: 40px;
    }
  }
}
