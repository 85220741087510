@import "../../../../style/main.scss";

.tabContainer {
  display: flex;
  .tab {
    @include Noto-Sans-TCCallout143-Bold;
    cursor: pointer;
    padding: 11px 12px;
    color: $gs-006;
    display: flex;
    align-items: center;
    border-bottom: 3px solid transparent;
    svg {
      font-size: 21px;
      margin-right: 8px;
    }
    &:hover {
      // color: $primary-color;
      transition: 0.3s;
      background: rgba($primary-color, 0.1);
      border-radius: 8px 8px 0px 0px;
    }
    &:active {
      background: rgba($primary-color, 0.2);
      border-radius: 8px 8px 0px 0px;
    }
    &.active {
      border-bottom: 3px solid $primary-color;
      color: $primary-color;
      transition: 0.3s;
      &:hover {
        background: none;
        border-radius: none;
      }
    }
    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }
}
