@import "../../../../style/main.scss";
.modal {
  display: none;
}

.modalOpen {
  display: flex;
  position: fixed;
  justify-content: flex-end;
  align-items: center;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);

  .modalContentLg {
    box-sizing: border-box;
    background-color: #fefefe;
    padding: 48px;
    border-radius: 16px;
    width: 75%;
    height: 100%;
  }
  .modalContentMd {
    box-sizing: border-box;
    background-color: #fefefe;
    padding: 48px;
    border-radius: 16px;
    width: 45%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
  }
}
