@import "../../../style/main.scss";

.loadingWrapper {
  height: 100%;
  display: flex;
  width: 100%;
  z-index: 999;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f863;
  position: fixed;
  right: 0;

  @media (max-width: 1440px) {
    width: 100%;
  }
}


.dashboardOuter {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: calc(100vw - 330px);
  margin: 40px 32px 45px 72px;
  padding-bottom: 180px;

  &.leftNavigationShow {
    width: calc(100vw - 110px);
  }
}

.header {
  color: $gs-009;
  cursor: default;
  font-family: NotoSansTC, sans-serif;
  font-size: 24px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.24px;
  margin-bottom: 13px;
  padding-bottom: 17px;
  box-sizing: border-box;
  border-bottom: 1px solid $gs-002;
}

.body {
  font-family: NotoSansTC, sans-serif;
  font-size: 20px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.24px;
  box-sizing: border-box;

  .bodyTitle {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: $media-lg) {
      flex-direction: column;
      gap: 15px;
    }

    .searchBlock {
      display: flex;
      gap: 12px;
    }
  }

  .bodyTitleSeverError {
    font-size: 16px;
    font-weight: 700;
    color: #ff766e;
    margin-left: 10px;
  }
}

.enterpriseSearchBtn {
  width: 65px;
  height: 37px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  background: #015cff;
  @include Noto-Sans-TCCallout152-Medium;
}

.enterprisePurpleBtn {
  height: 37px;
  padding: 5px 12px 6px 12px;
  box-sizing: border-box;
  border-radius: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
  color: #fff;
  background: #5856d6;
  gap: 8px;
  @include Noto-Sans-TCCallout152-Medium;
}

.enterpriseWhiteBtn {
  height: 37px;
  padding: 5px 12px 6px 12px;
  box-sizing: border-box;
  border-radius: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #0000000d;
  color: #676767;
  background: #ffffff;
  gap: 8px;
  @include Noto-Sans-TCCallout152-Medium;
}

.addContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $gs-009;
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  background-color: #ffffff;
  padding: 8px 24px 16px 0px;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.24px;
  border-radius: 16px 16px 0px 0px;

  span {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.addMiddleContent {
  width: 100%;
  padding: 20px 0px;
  background: #ffffff;
  box-sizing: border-box;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.24px;

  .tableContent {
    width: 100%;
    box-shadow: 0px 1px 8px 0px #00000014;
    border-radius: 16px;
    background: #f7f7f7;
    border: 1px solid #d9d9d9;
    min-height: 580px;
    overflow: hidden;
    padding-top: 11px;
    padding-bottom: 11px;
    box-sizing: border-box;
    padding-bottom: 180px;

    .halfBlock {
      width: 97%;
      display: flex;
      justify-content: space-between;
      margin: auto;
      margin-bottom: 12px;
    }

    .block {
      width: 97%;
      margin: auto;
      display: flex;
      background-color: #ffffff;
      border-radius: 8px;
      margin-bottom: 12px;
      padding: 7px 16px;
      box-sizing: border-box;
      font-weight: 500;
      font-size: 14px;
      height: 52px;
      align-items: center;

      @media (max-width: $media-lg) {
        flex-direction: column;
        height: auto;
        gap: 15px;
      }

      &.halfBlockLeft {
        width: 44%;
        margin: 0;
      }

      &.halfBlockRight {
        width: 54%;
        margin: 0;
      }

      &.halfBlockLeftNone {
        width: 46%;
        margin: 0;
        border-radius: 8px 0px 0px 8px;
        padding: 7px 0px 7px 16px;

        @media (max-width: $media-xll) {
          width: 50%;
        }
      }

      &.halfBlockRightNone {
        width: 54%;
        margin: 0;
        border-radius: 0px 8px 8px 0px;
        padding: 7px 16px;

        @media (max-width: $media-xll) {
          width: 50%;
        }
      }

      &.final {
        height: auto;
        display: block;
        padding: 16px;

        .finalTitleContent {
          display: flex;
          align-items: center;
          gap: 15px;
        }
      }

      .blockTitle {
        width: 13%;
        display: flex;
        align-items: center;

        @media (max-width: $media-lg) {
          width: 100%;
        }

        &.halfLeft {
          width: 31%;

          @media (max-width: $media-lg) {
            width: 100%;
          }
        }

        &.halfRight {
          width: 25%;

          @media (max-width: $media-lg) {
            width: 100%;
          }
        }

        &.halfText {
          width: 16%;

          @media (max-width: $media-lg) {
            width: 100%;
          }
        }

        &.halfRightText {
          width: 16%;

          @media (max-width: $media-lg) {
            width: 100%;
            margin-top: 34px;
          }
        }

        &.halfLeftText {
          width: 29%;

          @media (max-width: $media-xll) {
            width: 20%;
          }

          @media (max-width: $media-lg) {
            width: 100%;
          }
        }

        &.disabled {
          color: #979797;
        }
      }

      .selectBlock {
        display: flex;
        margin-left: 30px;
        gap: 10px;
        font-size: 14px;
        font-weight: 500;
        align-items: center;

        @media (max-width: $media-lg) {
          margin-left: 0px;
        }
      }

      .selectContent {
        width: 150px;
      }

      .blockTextInput {
        width: 85%;
        background-color: #ffffff;
        border: 1px solid #eeeeee;
        height: 37px;
        border-radius: 8px;
        padding: 5px 10px;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: 500;

        @media (max-width: $media-lg) {
          width: 100%;
        }
      }

      .importDataModalMidStepButton {
        display: flex;
        align-items: center;
        position: relative;
        gap: 10px;
        font-family: NotoSansTC, sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.46;
        letter-spacing: 0.4px;
        label {
          width: 112px;
          padding: 1px;
          cursor: pointer;
          input[type="file"] {
            display: none;
          }
          &:hover {
            button {
              background: rgba(#999999, 0.3) !important;
            }
          }
          button {
            pointer-events: none;
          }
        }
      }
      .filedName {
        font-family: NotoSansTC, sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 1px;
        margin-left: 5px;
        color: #005eff;
        display: flex;
        align-items: center;
        width: 145px;
        height: 50px;
        overflow: hidden;

        @media (max-width: $media-xll) {
          width: 100px;
        }

        @media (max-width: $media-lg) {
          width: 100%;
        }

        &.widthOver {
          width: 450px;

          @media (max-width: $media-lg) {
            width: 100%;
          }
        }

        .labelError {
          @include Noto-Sans-TCCaption122-Medium;
          color: $system-red;

          &.half {
            width: 145px;

            @media (max-width: $media-xll) {
              width: 100px;
            }

            @media (max-width: $media-lg) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.templateContent {
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 12px;
  min-height: 200px;
  @include Noto-Sans-TCCaption132-Medium;
  color: #676767;
}

.modalBottomContent {
  color: $gs-009;
  width: calc(100% - 280px);
  border-top: 1px solid #eeeeee;
  background-color: #ffffff;
  padding: 16px 24px;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.24px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99;

  &.leftNavigationShow {
    width: calc(100%);
  }

  .articleError {
    display: flex;
    align-items: center;
    gap: 10px;
    min-width: 10px;
    height: 37px;
    font-size: 16px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.24px;
    color: #ff766e;
  }

  .btnBlock {
    display: flex;
    gap: 20px;
  }
}

.addModalTemplate {
  width: 100%;
  height: 90vh;

  .addModalTemplateTop {
    width: 100%;
    height: 8%;
    border-bottom: 1px solid #eeeeee;
    padding: 0px 24px;
    box-sizing: border-box;
    font-family: NotoSansTC, sans-serif;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.07px;
    display: flex;
    align-items: center;
  }

  .addModalTemplateMiddle {
    width: 100%;
    height: 84%;
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    padding: 12px 24px;
    color: #666666;
    @include Noto-Sans-TCCallout142-Medium;

    p {
      margin: 16px 0px;
    }
  }

  .addModalTemplateBottom {
    width: 100%;
    height: 8%;
    border-top: 1px solid #eeeeee;
    padding: 0px 24px;
    box-sizing: border-box;
    font-family: NotoSansTC, sans-serif;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.07px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.addModalTemplateTextBlock {
  width: 100%;
  border-bottom: 1px solid #eeeeee;

  p {
    margin-bottom: 15px;
  }
}

.addModalTemplateBlock {
  width: 100%;
  border: 1px solid #d9d9d9;
  margin-bottom: 24px;
  color: #333333;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 16px;
  cursor: pointer;
  font-size: 20px;

  &.styleChange {
    display: flex;
    justify-content: space-between;

    @media (max-width: $media-lg) {
      flex-direction: column;
      gap: 40px;
    }
  }

  &.selectedDiv {
    border: 2px solid #5856d699;
    background: #5856d614;
  }
}

.mainTitle {
  width: 100%;
  display: flex;
  font-size: 28px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;
}

.previewTitle {
  width: 100%;
  font-weight: 700;
  margin-bottom: 35px;
}

.introductionBlock {
  width: 47%;

  @media (max-width: $media-lg) {
    width: 100%;
  }
}

.introductionImgTextBlock {
  width: 67%;

  @media (max-width: $media-lg) {
    width: 100%;
  }
}

.imgTextBlock {
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: $media-lg) {
    flex-direction: column;
    gap: 40px;
  }

  .imgText {
    width: 48%;
    display: flex;
    justify-content: space-between;

    @media (max-width: $media-lg) {
      width: 100%;
      flex-direction: column;
    }
  }

  .imgTextRight {
    width: 50%;

    @media (max-width: $media-lg) {
      width: 100%;
    }
  }

  .imgTextCenter {
    width: 29%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    @media (max-width: $media-lg) {
      width: 100%;
    }
  }
}

.introduction {
  display: flex;
  align-items: center;
  width: 132px;
  height: 35px;
  padding: 0px 12px;
  color: #ffffff;
  background-color: #2e5591;
  @include Noto-Sans-TCCallout182-Medium;
  border-radius: 10px;
  margin-bottom: 12px;
}

.imgContainer {
  width: 100%;
  position: relative;

  img {
    width: 100%;
    border-radius: 16px;
  }

  &.small {
    width: 30%;
    height: fit-content;

    @media (max-width: $media-lg) {
      width: 100%;
    }
  }

  &.middle {
    width: 45%;

    @media (max-width: $media-lg) {
      width: 100%;
    }
  }

  .imgUpdateBtn {
    position: absolute;
    width: 100%;
    height: 98%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    top: 0;
    border-radius: 16px;
  }
}

.addModalTemplateBlock:hover {
  box-shadow: 0px 2px 12px 0px #0000001f;
  border: 1.5px solid #5856d6cc;
}

.editorBlock {
  position: relative;
  color: #333333;
  box-sizing: border-box;
  padding: 16px;
  cursor: pointer;
  font-size: 20px;
  border: 1px solid transparent;
}

.editorBlockFlex {
  display: flex;
  justify-content: space-between;

  &.width_100 {
    width: 100%;
  }

  @media (max-width: $media-lg) {
    flex-direction: column;
    gap: 40px;
  }
}

.width_29 {
  width: 29%;

  @media (max-width: $media-lg) {
    width: 100%;
  }
}

.width_45 {
  width: 45%;
  height: fit-content;

  @media (max-width: $media-lg) {
    width: 100%;
  }
}

.width_47 {
  width: 47%;

  @media (max-width: $media-lg) {
    width: 100%;
  }
}

.width_48Flex {
  width: 48%;
  display: flex;
  justify-content: space-between;
  justify-items: flex-start;

  @media (max-width: $media-lg) {
    width: 100%;
    flex-direction: column;
    gap: 40px;
  }
}

.width_50 {
  width: 50%;

  @media (max-width: $media-lg) {
    width: 100%;
  }
}

.width_67 {
  width: 67%;

  @media (max-width: $media-lg) {
    width: 100%;
  }
}

.editorBlock:hover {
  border: 1px solid #989898;
}

.editorBlockOptionBtn {
  background-color: #ffffff;
  align-items: center;
  display: flex;
  gap: 5px;
  width: 150px;
  padding: 0px 5px;
  height: 45px;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  bottom: -45px;
  box-shadow: 0px 0px 20px 0px #00000033;
  border-radius: 8px;
  z-index: 98;
}

.editorLabelOptionBtn {
  background-color: #ffffff;
  align-items: center;
  display: flex;
  padding: 0px 10px;
  height: 45px;
  box-sizing: border-box;
  position: absolute;
  top: 50px;
  box-shadow: 0px 0px 20px 0px #00000033;
  border-radius: 8px;
  z-index: 99;
}

.optionBtn {
  width: 25%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.optionBtnIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 4px;
}

.optionBtnIcon:hover {
  background-color: #ededed;
}

.colorBlock {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  box-sizing: border-box;
  position: absolute;
  left: 150px;
  z-index: 99;
}

.colorLabelBlock {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  box-sizing: border-box;
  position: absolute;
  z-index: 99;
  left: 45px;
  top: 0;
}

.uploadPreviewButton {
  display: flex;
  align-items: center;
  position: relative;
  gap: 10px;
  font-family: NotoSansTC, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.46;
  letter-spacing: 0.4px;
  label {
    padding: 1px;
    cursor: pointer;
    input[type="file"] {
      display: none;
    }
    &:hover {
      button {
        background: rgba(#e9e9e9, 0.9) !important;
      }
    }
    button {
      pointer-events: none;
    }
  }
}

.noDataContainer {
  width: 100%;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666666;
  font-family: NotoSansTC, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  letter-spacing: 0.05px;
}

.tableContainer {
  table {
    width: 100%;

    th {
      color: #666666;
      border-bottom: 1px solid $gs-003;
      background-color: $system-table;
      padding: 15px;
      font-size: 14px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.06px;
    }

    td {
      padding: 25px 15px;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.02px;
      color: #333333;
      border-bottom: 1px solid #eeeeee;
    }

    .allCenter {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .iconContent {
      .btn {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        cursor: pointer;
        padding: 4px;
        margin-right: 10%;
      }

      .btn:hover {
        border-radius: 4px;
        background-color: #ededed;
      }
    }
  }
}

.previewArticleBlockTitle {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #ffffff;
  font-family: NotoSansTC, sans-serif;
  font-size: 36px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  box-sizing: border-box;
  padding: 45px 9% 25px 9%;
  color: #4d4d4d;
  line-height: 1.73;
  letter-spacing: 0.06px;
}

.previewArticleModal {
  height: 90vh;
  .previewArticleTopBlock {
    display: flex;
    align-items: center;
    padding: 16px 25px;
    border-bottom: 1px solid #eeeeee;
    background-color: #e6e6e6;
    border-radius: 16px 16px 0px 0px;
    box-sizing: border-box;
    height: 8.5%;

    .previewArticleTopBlockTitle {
      width: 55px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #d9d9d9;
      color: #333333;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.79;
      letter-spacing: 0.07px;
      border-radius: 8px;
    }
  }

  .previewArticleMiddleBlock {
    height: 83%;
    background-color: #ffffff;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;

    .previewArticleMiddleBlockTopDate {
      display: flex;
      width: 100%;
      background-color: #ffffff;
      color: #b5b5b6;
      font-family: NotoSansTC, sans-serif;
      font-size: 16px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      box-sizing: border-box;
      padding: 60px 9% 10px 9%;
    }
  }

  .previewArticleBottomBlock {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px 25px;
    border-top: 1px solid #eeeeee;
    background-color: #ffffff;
    border-radius: 0px 0px 16px 16px;
    box-sizing: border-box;
    height: 8.5%;

    .previewArticleBottomBlockBtn {
      width: 80px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #5856d6;
      color: #ffffff;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.79;
      letter-spacing: 0.07px;
      border-radius: 8px;
      cursor: pointer;
    }
  }
}

.previewModalArticleBlock {
  width: 100%;
  box-sizing: border-box;
  padding: 40px 9%;
  word-wrap: break-word;
  line-height: 1.73;
  letter-spacing: 0.06px;
  font-family: NotoSansTC, sans-serif;
  font-stretch: normal;
  font-style: normal;

  &.styleChange {
    display: flex;
    justify-content: space-between;

    @media (max-width: $media-lg) {
      flex-direction: column;
      gap: 40px;
    }
  }
}


.margin_1EmStyle{
  font-weight: 400;
  font-size: 20px;
}

.margin_05EmStyle{
  margin: 0.5em 0;
}
