@import '../../../../style/main.scss';

.tableContainer {
  margin: 32px 0px;
  box-sizing: border-box;
  .title {
    @include Noto-Sans-TCCallout163-Bold;
  }
  .iconOuter {
    display: flex;
    justify-content: space-between;
    margin-right: 5px;
    .iconOuter {
      display: flex;
      .iconFlex {
        position: relative;
        .icon {
          cursor: pointer;
          width: 20px;
          height: 20px;
          margin: 0px 8px;
          color: $primary-color;
          &:last-child() {
            width: 18px;
            height: 18px;
          }
        }
        .redDot {
          width: 2px;
          height: 2px;
          background: $system-red;
          border-radius: 50%;
          border: 1px solid red;
          position: absolute;
          top: 0;
          right: -5px;
        }
        .tip {
          position: absolute;
          top: 40px;
          left: 0;
          background-color: $gs-008;
          border-radius: 8px;
          padding: 6px 8px;
          color: $gs-000;
          z-index: $zIndex-button;
          text-align: center;
          cursor: pointer;
          @include Noto-Sans-TCCaption121-Regular;
        }
      }
    }
  }
  table {
    margin-top: 16px;
    width: 100%;

    th {
      width: 25%;
      background: $system-pgrey;
      padding: 10px 16px;
      color: $gs-006;
      @include Noto-Sans-TCCaption131-Regular;
      &:nth-child(1) {
        border-radius: 10px 0px 0px 10px;
      }
      &:last-child {
        border-radius: 0px 10px 10px 0px;
      }
    }
    .emptybox {
      width: 100%;
    }
    tr {
      margin-top: 6px;
    }
    .editModeTd {
      min-height: 173px;
      padding: 6px;
      position: relative;
      .textAreaOuter {
        background: $gs-001;
        z-index: 0;
        border-radius: 8px;
        .editMode {
          position: relative;
          border: 1px solid $gs-003;
          border-radius: 8px;
          background: transparent;
          height: 173px;
          width: 100%;
          resize: none;
          box-sizing: border-box;
          padding: 10px;
          @include Noto-Sans-TCBody141-Regular;
          z-index: 2;
        }
      }
      .textAeraIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #dddddd;
        width: 40px;
        height: 40px;
        z-index: 0;
      }
    }
    .nonEditdTd {
      min-height: 300px;
      padding: 6px;
      position: relative;
      .nonEditMode {
        border: 1px solid $gs-003;
        border-radius: 8px;
        height: 300px;
        width: 100%;
        resize: none;
        box-sizing: border-box;
        padding: 10px;
        @include Noto-Sans-TCBody141-Regular;
        white-space: pre-wrap;
        word-wrap: break-word;
      }
    }
  }
}
.modalContainer {
  height: 600px;
  position: relative;
  .header {
    padding-bottom: 16px;
    border-bottom: 1px solid $gs-002;
    margin-bottom: 16px;

    .title {
      @include Noto-Sans-TCTitle243-Bold;
      color: $primary-color;
      display: flex;
      align-items: center;
      .icon {
        margin-right: 16px;
      }
    }
  }
  .body {
    margin-top: 16px;
    .cycleTitle {
      color: $gs-006;
      @include Noto-Sans-TCCaption131-Regular;
    }
    .cycleName {
      color: $gs-009;
      @include Noto-Sans-TCFootnote142-Medium;
      margin-top: 8px;
    }
    .attributeName {
      margin-top: 24px;
      @include Noto-Sans-TCFootnote152-Medium;
    }
  }
  .modalTableContainer {
    // display: flex;
    margin-top: 16px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 400px;
    table {
      margin: 5px;
      width: 100%;
      th {
        color: $gs-006;
        @include Noto-Sans-TCBody141-Regular;
        padding: 10px 16px;
        background: $system-pgrey;
        &:nth-child(1) {
          border-radius: 10px 0px 0px 10px;
        }
        &:last-child {
          border-radius: 0px 10px 10px 0px;
        }
      }
      .tableIndex {
        height: 49px;
        .index {
          @include Noto-Sans-TCCallout143-Bold;
          color: $gs-008;
          margin-left: 6px;
          align-items: center;
        }
        input {
          background: $gs-001;
          border: 1px solid $gs-002;
          border-radius: 8px;
          height: 36px;
          width: 90%;
          padding: 0px 6px;
          @include Noto-Sans-TCFootnote141-Regular;
        }
      }
    }
  }
}
.modalFooter {
  border-top: 1px solid $gs-003;
  height: 100px;
  position: sticky;
  .footerContainer {
    display: flex;
    justify-content: space-between;
    .btnGroup {
      margin-top: 30px;
      align-items: center;
    }
  }
}
