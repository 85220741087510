@import '../../../../style/main.scss';

.tabs {
  display: inline-flex;
  // background: $gs-003;
  background: $system-pgrey;
  height: 43px;
  border-radius: 21.5px;
  font: 14px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
}

.tab {
  position: absolute;
  height: 37px;
  background: white;
  border-radius: 18.5px;
  margin: 3px;
  transition: all 0.5s ease;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.08);
}

.tabList {
  padding: 3px;
  display: flex;
  z-index: 1;
  width: 100%;
}

.tab-value {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  @include Noto-Sans-TCCallout142-Medium;
  // color: $gs-007;
  color: $gs-007;
}

.tab-value-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary-color;
  @include Noto-Sans-TCCallout143-Bold;
}
