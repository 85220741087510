@charset "utf-8";
@import "../../../../style/main.scss";

%normal-button {
  @include Noto-Sans-TCCallout152-Medium;
  height: 37px;
  padding: 5px 12px 6px 12px;
  box-sizing: border-box;
  border-radius: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: $zIndex-button;
  cursor: pointer;
}

.wrapper {
  position: relative;
  .children {
    position: absolute;
    right: 0;
    box-shadow: 2px 16px 50px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    background-color: $gs-000;
    padding: 6px;
    min-width: 200px;
    top: 27px;
    z-index: 5;
    button {
      justify-content: flex-start;
      &:hover {
        background-color: rgba($primary-color, 0.1);
      }
    }
  }
}

.more {
  // @extend %normal-button;
  @include Noto-Sans-TCCallout152-Medium;
  box-sizing: border-box;
  justify-content: center;
  z-index: $zIndex-button;
  cursor: pointer;
  //
  border: none;
  background: transparent;
  color: $primary-color;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  padding: 0 !important;
}

.rotate180 {
  transform: rotate(180deg);
  transition: 0.3s;
}
