@import "../../../style/main.scss";

.representIconOuter {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -10px;
  .tips {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 240px;
    padding: 6px 8px;
    top: -60px;
    transform: translateX(-80%);
    left: 50%;
    background: rgba($gs-008, 0.9);
    color: $gs-000;
    border-radius: 6px;
    @include Noto-Sans-TCCaption121-Regular;
  }
  .tips:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-width: 8px;
    border-style: solid;
    border-top-color: rgba($gs-008, 0.9);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    bottom: -16px;
    left: 80%;
    transform: translateX(-50%);
  }
}

.dashboardWorkspace {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  @include Montserrat-Semi-Bold;

  .tabsContainer {
    margin: 12px 56px 16px 72px;
  }

  .dashboardTitle {
    margin: 40px 56px 29px 72px;
    padding-bottom: 29px;
    @include Montserrat-Semi-Bold;
    border-bottom: 1px solid $gs-003;
  }

  .dashboardListContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 56px 20px 72px;

    .dashboardListTitle {
      @include Noto-Sans-TCTitle243-Bold;
      font-weight: 700;
      color: $gs-005;
    }

    .singedListTitle {
      @include Noto-Sans-TCTitle243-Bold;
      font-weight: 700;
      color: #000000;
    }

    .singedBtnContainer {
      display: flex;
      gap: 10px;

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 39px;
        gap:10px;
        padding: 0px 20px;
        background-color: #5856d6;
        color: #fff;
        transition: 0.5s;
        font-family: NotoSansTC, sans-serif;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: -0.06px;
        border-radius: 12px;
        cursor: pointer;

        svg{
          margin-top: -2px;
        }
      }
    }
  }

  .searchBar {
    display: flex;
    justify-content: space-between;
    margin: 16px 56px 20px 72px;

    .searchOutline {
      position: relative;
      width: 344px;

      input {
        width: 320px;
        line-height: 27px;
        padding: 10px 8px 10px 16px;
        border: 0;
        font-size: 16px;
        font-weight: 400;
        border-radius: 12px;

        &:disabled {
          background: #ccc;
          opacity: 50%;
        }
      }

      .BsSearch {
        display: inline-block;
        position: absolute;
        right: 4%;
        top: 50%;
        transform: translateY(-40%);
        cursor: pointer;
      }
    }
  }

  .mdRelative {
    position: relative;
    height: 100%;

    .ModalTitle {
      @include Montserrat-Bold;
      font-size: 16px;
      margin-bottom: 21px;
    }

    .ModalName {
      @include Noto-Sans-TCBody142-Medium;
      margin-bottom: 8px;

      span {
        color: $gs-005;
      }
    }

    .errMsg {
      @include Noto-Sans-TCBody142-Medium;
      color: $system-error;
    }
  }
  .btnContainer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    position: sticky;
    bottom: 0;
    right: 0;

    .btnflex {
      width: 10px;
    }
  }

  .tableContainer {
    margin: 16px 56px 24px 72px;

    table {
      width: 100%;

      th {
        color: $gs-006;
        font-size: 16px;
        border-bottom: 1px solid $gs-003;
        background-color: $system-table;
      }

      th,
      td {
        padding: 8px;
        @include Noto-Sans-TCBody161-Regular;
      }

      .tableTd {
        padding: 8px 8px;
        border-bottom: 1px solid $gs-003;
      }

      .tableTd {
        @include Montserrat-Semi-Bold;
        font-size: 15px;
        color: $gs-008;
      }

      .dropLists {
        cursor: pointer;
      }
    }

    .details {
      box-sizing: border-box;
      background-color: $gs-001;
      width: 100%;
      transition: all 1s ease-out;
      @include Montserrat-Semi-Bold;
      font-size: 16px;
      border-radius: 16px;

      .wrapContainer {
        display: flex;
        flex-wrap: wrap;
        align-content: center;

        .detailsListContainer {
          width: 40%;
          display: flex;
          align-items: center;
          padding: 16px 32px;
        }

        .detailsListTitle {
          width: 30%;
          @include Noto-Sans-TCBody161-Regular;
          color: $gs-007;
        }
      }
    }
  }

  .paginationContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    @include Noto-Sans-TCFootnote141-Regular;
    color: $gs-006;
  }

  .noResult {
    width: 100%;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;

    h4 {
      color: $gs-007;
    }
  }
}

.workspaceListClose {
  display: none;
}

.workspaceListOpen {
  display: block;
  z-index: 48;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  // height: 100vh;
  min-height: 100vh;
  background-color: $gs-000;

  .ws {
    @include Montserrat-Semi-Bold;

    .titleContainer {
      margin: 40px 0px 29px 0px;
      padding-bottom: 15px;
      border-bottom: 1px solid $gs-003;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .dflex {
        display: flex;
        align-items: center;
        margin-right: 5px;
      }

      .dashboardTitle {
        @include Montserrat-Semi-Bold;
        border-bottom: 0;
        margin: 0;
        padding: 0;
        margin-right: 15px;
      }

      .inputClose {
        display: none;
      }

      .inputContainer {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
      }

      .editWorkspace {
        padding: 10px 112px 10px 16px;
        outline: 0px;
        border: 0px;
        line-height: 26px;
        border-radius: 12px;
        background: $system-pgrey;
      }

      .editWorkspaceError {
        padding: 10px 112px 10px 16px;
        outline: 0px;
        line-height: 26px;
        border-radius: 12px;
        border: 1px solid #ff0000;
      }

      .errorMsg {
        color: red;
        font-size: 12px;
      }

      .iconContainer {
        position: absolute;
        top: 5px;
        right: 2px;
        display: flex;
      }

      .iconSquare {
        width: 34px;
        height: 36px;
        background-color: #eeeeee;
        margin: 0px 4px;
        border-radius: 10px;
        cursor: pointer;
        position: relative;
      }

      .iconWidth {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .headerRightContainer {
        display: flex;
        align-items: center;
        @include Noto-Sans-TCFootnote142-Medium;
        min-width: 565px;
        button {
          margin-right: 5px;
          &:last-child {
            margin-right: 0;
          }
        }
      }

      .mright {
        margin-right: 30px;
        cursor: pointer;
        display: flex;
        align-items: center;
      }

      .iconMargin {
        margin-left: 7px;
      }
    }

    .dashboardListContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 16px 0px 20px 0px;

      .dashboardListTitle {
        @include Noto-Sans-TCTitle243-Bold;
        font-weight: 700;
        color: $gs-005;
      }
    }

    .searchBar {
      display: flex;
      justify-content: space-between;
      margin: 16px 0px 20px 0px;
      .searchOutline {
        position: relative;
        width: 344px;
        // border: 1px solid red;
        input {
          width: 320px;
          line-height: 27px;
          padding: 10px 8px 10px 16px;
          border: 0;
          font-size: 16px;
          font-weight: 400;
          border-radius: 12px;
          &:disabled {
            background: #ccc;
            opacity: 50%;
          }
        }
        .BsSearch {
          display: inline-block;
          position: absolute;
          right: 4%;
          top: 50%;
          transform: translateY(-40%);
          cursor: pointer;
        }
      }
    }

    margin: 16px 56px 24px 72px;

    table {
      width: 100%;

      th {
        color: $gs-006;
        font-size: 16px;
        border-bottom: 1px solid $gs-003;
        background-color: $system-table;
        box-sizing: border-box;
        &.btnGroup {
          min-width: 208px;
        }
        &.verify {
          min-width: 80px;
        }
        &.joint {
          min-width: 95px;
        }
        &.mail {
          min-width: 130px;
        }
        &.delete {
          min-width: 35px;
        }
      }

      th,
      td {
        padding: 8px;
      }

      .tableTd {
        padding: 8px 8px;
        border-bottom: 1px solid $gs-003;
        word-break: break-all;
      }

      .tableTd {
        @include Montserrat-Semi-Bold;
        font-size: 15px;
        color: $gs-008;
      }

      .btnContainer {
        display: flex;

        .btnflex {
          &:first-child {
            margin-right: 20px;
          }
        }
      }

      .ModalBtn {
        box-sizing: border-box;
        position: absolute;
        padding: 15px;
        width: 280px;
        background-color: $gs-000;
        -webkit-animation: fadeIn 1s;
        animation: fadeIn 1s;
        box-shadow: 2px 16px 50px rgba(0, 0, 0, 0.3);
        border-radius: 14px;
        z-index: 10;

        @-webkit-keyframes fadeIn {
          from {
            opacity: 0;
          }

          to {
            opacity: 90%;
          }
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
          }

          to {
            opacity: 90%;
          }
        }
      }

      .btnMargin {
        margin-top: 8px;
      }

      .dropLists {
        cursor: pointer;
      }
    }

    .details {
      box-sizing: border-box;
      background-color: $gs-001;
      width: 100%;
      transition: all 1s ease-out;
      @include Montserrat-Semi-Bold;
      font-size: 16px;
      border-radius: 16px;

      .wrapContainer {
        display: flex;
        flex-wrap: wrap;
        align-content: center;

        .detailsListContainer {
          width: 40%;
          display: flex;
          align-items: center;
          padding: 16px 32px;
          // border-bottom: 1px solid $gs-003;
        }

        .detailsListTitle {
          width: 30%;
          @include Noto-Sans-TCBody161-Regular;
          color: $gs-007;
        }
      }
    }

    .paginationContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 12px;
      padding-bottom: 98px;
      @include Noto-Sans-TCFootnote141-Regular;
      color: $gs-006;
    }
  }
}

.modal {
  display: none;
}

.modalOpen {
  visibility: visible;
  pointer-events: default;
  display: flex;
  position: fixed;
  justify-content: flex-end;
  align-items: center;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;

  @-webkit-keyframes fadeIn {
    from {
      opacity: 100%;
    }

    to {
      opacity: 0;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 100%;
    }

    to {
      opacity: 0;
    }
  }
}

/* Modal Content/Box */
.modalContentLg {
  box-sizing: border-box;
  background-color: #fefefe;
  padding: 48px;
  border-radius: 16px;
  width: 75%;
  height: 100%;

  .headerContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  .headerTitle {
    @include Noto-Sans-TCTitle283-Bold;
  }

  .headerContent {
    @include Noto-Sans-TCCallout141-Regular;
    color: $gs-009;
  }

  .assignContent {
    @include Noto-Sans-TCFootnote151-Regular;
    color: $gs-007;
    margin-bottom: 12px;
  }

  .listTitleContainer {
    display: flex;
    justify-content: space-between;

    .listTitle {
      @include Noto-Sans-TCTitle163-Bold;
    }

    .listAssigned {
      @include Noto-Sans-TCTitle203-Bold;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .searchBar {
      display: flex;
      justify-content: space-between;
      margin: 16px 0px 20px 0px;

      .searchOutline {
        position: relative;
        width: 344px;

        input {
          width: 320px;
          line-height: 27px;
          padding: 10px 8px 10px 16px;
          background: $gs-001;
          border: 0;
          font-size: 16px;
          font-weight: 400;
          border-radius: 12px;

          &:disabled {
            background: #ccc;
            opacity: 50%;
          }
        }

        .BsSearch {
          // border: 1px solid red;
          display: inline-block;
          position: absolute;
          right: 5%;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }
    }

    .tabcontainer {
      width: 30%;
    }
  }

  .tableContainer {
    margin: 16px 0px 24px 0px;
    height: calc(100vh - 350px);
    overflow-y: auto;
    overflow-x: hidden;

    .tableClipLoading {
      width: 100%;
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    table {
      width: 100%;
      th {
        @include Noto-Sans-TCBody161-Regular;
        color: $gs-006;
        font-size: 16px;
        border-bottom: 1px solid $gs-003;
        background-color: $system-table;
      }
      th,
      td {
        padding: 8px;
        &.tableTd {
          border-bottom: 1px solid $gs-003;
          @include Montserrat-Semi-Bold;
          font-size: 15px;
          color: $gs-008;
        }
      }

      .dropLists {
        cursor: pointer;
      }
    }

    .details {
      box-sizing: border-box;
      background-color: $gs-001;
      width: 100%;
      transition: all 1s ease-out;
      @include Montserrat-Semi-Bold;
      font-size: 16px;
      border-radius: 16px;

      .wrapContainer {
        display: flex;
        flex-wrap: wrap;
        align-content: center;

        .detailsListContainer {
          width: 40%;
          display: flex;
          align-items: center;
          padding: 16px 32px;
          // border-bottom: 1px solid $gs-003;
        }

        .detailsListTitle {
          width: 30%;
          @include Noto-Sans-TCBody161-Regular;
          color: $gs-007;
        }
      }
    }
  }

  .fixedContainer {
    width: 75%;
    position: fixed;
    z-index: 9999;
    display: flex;
    justify-content: space-between;
    left: 25%;
    bottom: 0;
    padding: 25px 58px;
    align-items: center;
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 0px 0px 16px 16px;

    .btnContainer {
      display: flex;

      .mr {
        margin-right: 8px;
      }
    }

    .listCount {
      @include Montserrat-Semi-Bold;
      font-size: 16px;
      color: $gs-006;
    }
  }
}

// WorkSpaceDetail
.headerContainer {
  display: flex;
  justify-content: space-between;

  .permissionTitle {
    @include Noto-Sans-TCTitle283-Bold;
  }

  .headerRight {
    @include Noto-Sans-TCFootnote141-Regular;
    color: $gs-006;
  }
}

.headerTop {
  @include Noto-Sans-TCFootnote152-Medium;
  color: $gs-007;
  padding: 18px 0px;
}

.headContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.systemTitle {
  @include Montserrat-Semi-Bold;
  font-size: 20px;
}

.listTopic {
  @include Noto-Sans-TCTitle163-Bold;
  margin-top: 12px;
  margin-bottom: 20px;
}

.managementlist {
  display: flex;
  flex-wrap: wrap;
  .checkboxOuter {
    box-sizing: border-box;
    width: calc(50% - 8px);
    &:nth-child(odd) {
      margin-right: 8px;
    }
    &:nth-child(even) {
      margin-left: 8px;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid $gs-003;
    .planTitle {
      @include Noto-Sans-TCCallout161-Regular;
    }
  }
}
.drawerFooter {
  box-sizing: border-box;
  width: 45%;
  position: fixed;
  bottom: 0;
  left: 55%;
  padding: 16px 40px;
  background: #ffffff;
  border-radius: 0px 0px 16px 16px;
  box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: flex-end;
}

.settingsContainer {
  height: 75vh;
  overflow: auto;
  position: fixed;
  width: 40%;

  .settingTitle {
    color: $gs-008;
    @include Montserrat-Semi-Bold;
    font-size: 20px;
    margin: 17px 0px;
  }

  .listTopic {
    @include Noto-Sans-TCTitle163-Bold;
    margin-bottom: 12px;
  }

  .listContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;

    .listChoiceContainer {
      width: 48%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      border-bottom: 1px solid $gs-003;
      @include Noto-Sans-TCCallout161-Regular;
    }

    .disabledChoiceTitle {
      color: $gs-005;
    }
  }
}

.footerContainer {
  box-sizing: border-box;
  width: 45%;
  position: fixed;
  bottom: 0;
  left: 55%;
  padding: 16px 40px;
  background: #ffffff;
  border-radius: 0px 0px 16px 16px;
  box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.08);

  .btnFlex {
    display: flex;
    justify-content: space-between;

    .dflex {
      display: flex;
      align-items: center;
      button {
        &:first-child {
          margin-right: 10px;
        }
      }

      .btnClear {
        @include Noto-Sans-TCFootnote141-Regular;
        color: $gs-008;
        margin-left: 20px;
        cursor: pointer;
        user-select: none;
      }
      .btnClearDisabled {
        color: $gs-006;
        cursor: not-allowed;
      }
      .mright {
        margin-right: 8px;
      }
    }
  }
}

// UserCreateModal
.userModalOpen {
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 20%;
  right: 10%;
  z-index: $zIndex-modal;
  width: 296px;
  height: 418px;
  background-color: #ffffffe5;
  opacity: 100%;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
  border-radius: 16px;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(30px);

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 100%;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 100%;
    }
  }

  .modalheaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 24px;
    padding: 12px 0px;
    @include Noto-Sans-TCTitle163-Bold;
    border-bottom: 1px solid $gs-002;

    .headerTitle {
      line-height: 24px;
    }

    .closeModalOuter {
      width: 44px;
      height: 44px;
      background-color: $gs-002;
      border-radius: 12px;
      position: relative;
      cursor: pointer;

      .closeModal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .modalInputOuter {
    margin: 8px 24px 20px 24px;

    .inputTitle {
      @include Noto-Sans-TCFootnote142-Medium;
      line-height: 25px;
      margin-bottom: 8px;
    }

    .errorMsg {
      font-size: 12px;
      color: red;
    }

    .errorInput {
      border: 1px solid red;
      outline: 0px;
      width: 100%;
      box-sizing: border-box;
      padding: 10px 16px;
      background: $gs-002;
      border-radius: 12px;
      line-height: 27px;
      margin-bottom: 8px;
    }

    .input {
      border: 0px;
      outline: 0px;
      width: 100%;
      box-sizing: border-box;
      padding: 10px 16px;
      background: $gs-002;
      border-radius: 12px;
      line-height: 27px;
      margin-bottom: 8px;
    }
  }

  .footerBtn {
    margin: 5px 24px;
    display: flex;
    justify-content: flex-end;
  }

  .btnDefault {
    font-family: NotoSansTC, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.79;
    letter-spacing: 0.05px;
    border: none;
    padding: 8px 20px 9px;
    background: #ddd;
    border-radius: 12px;
    width: 100%;
    cursor: pointer;
  }
}

.tableClipLoading {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.iconSpan{
  margin-left: 10px;
  position: relative;
  
  svg{
    position: absolute;
    top: 3px;
    left: -3px;
  }
}

@media (min-width: 1024px) {
  .inner-layout {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

@media (max-width: $media-xl) {
  .inner-layout {
    left: 0;
    width: 100%;
  }
}
