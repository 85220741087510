@import '../../../style/main.scss';

.formSubtitle {
  @include Noto-Sans-TCTitle163-Bold;
  color: $gs-008;
  padding-bottom: 2px;
}

.formGroup {
  @include Noto-Sans-TCTitle163-Bold;
  color: $gs-008;
  padding-bottom: 8px;
  margin-top: 20px;
  font-weight: 500;
  font-size: 15px;
  text-align: left;
}

.error {
  @include Noto-Sans-TCTitle163-Bold;
  color: $gs-008;
  margin-top: 5px;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  color: $system-red;
}

.flex {
  display: flex;
  align-items: center;
  .emailVerified {
    color: $tdp-green;
    margin-right: 5px;
    font-size: 22px;
    margin-top: 7px;
  }
}

.checkIcon {
  display: flex;
  align-items: center;
  font-size: 21px;
  color: $system-green;
  text-align: center;
  justify-content: center;
}

.loadingWrapper {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.giftState {
  display: flex;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  opacity: 1;
  background: #b3b3b3;
  color: #ffffff;
  font-size: 15px;
  &.notPurchased {
    background: #ffffff;
    color: #989898;
    border: 1px solid #989898;
  }
}

.reserveWrapper {
  position: relative;
  display: flex;
  .reserveDate {
    margin-right: 5px;
    vertical-align: middle;
  }
  .planPeriodLayout {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: -32px;
    left: calc(60%);
    width: 265px;
    z-index: 999;
    background-color: #fff;
    border-radius: 14px;
    box-shadow: 2px 16px 50px rgba(0, 0, 0, 0.3);
    padding: 12px;
    gap: 6px;
    .planPeriod {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
    .planPeriodButton {
      display: flex;
      justify-content: flex-end;
      width: calc(100% - 20px);
      gap: 16px;
    }
  }
}

.dashboardRight {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  .dashboardTitle {
    margin: 40px 56px 29px 72px;
    padding-bottom: 29px;
    @include Montserrat-Semi-Bold;
    border-bottom: 1px solid $gs-003;
    color: $gs-007;
  }

  .dashboardSettings {
    @include Noto-Sans-TCFootnote141-Regular;
    margin: 20px 66px;

    .dashboardFlex {
      display: flex;

      .dashboardSettingsTitle {
        width: 170px;
        padding: 20px;
        color: $gs-006;
        font-size: 16px;
      }

      .dashboardSettingssContent {
        padding: 20px;
        font-size: 16px;
        display: flex;
      }

      .startService {
        padding-left: 10px;
      }
    }
  }
}

.dashboardBusinessRight {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  @include Montserrat-Semi-Bold;

  .tabsContainer {
    margin: 12px 56px 16px 72px;
  }

  .dashboardTitle {
    margin: 40px 56px 29px 72px;
    padding-bottom: 29px;
    @include Montserrat-Semi-Bold;
    border-bottom: 1px solid $gs-003;
    color: $gs-007;
  }

  .dashboardListContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 56px 20px 72px;

    .dashboardListTitle {
      @include Noto-Sans-TCTitle243-Bold;
      font-weight: 700;
      color: $gs-005;
    }

    .searchOutline {
      position: relative;

      input {
        width: 320px;
        line-height: 27px;
        padding: 10px 8px 10px 16px;
        border: 0;
        font-size: 16px;
        font-weight: 400;
        border-radius: 12px;
        background: $system-pgrey;
      }

      .BsSearch {
        display: inline-block;
        position: absolute;
        right: 5%;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }

  .tableContainer {
    margin: 16px 56px 24px 72px;

    table {
      width: 100%;

      th {
        color: $gs-006;
        font-size: 16px;
        border-bottom: 1px solid $gs-003;
        background-color: $system-table;
        &.center {
          text-align: center;
        }
      }

      th,
      td,
      .tableTd {
        padding: 12px 16px;
        border-bottom: 1px solid $gs-003;
        vertical-align: middle;
        &.center {
          text-align: center;
        }
        .basic {
          text-transform: capitalize;
          display: flex;
          padding: 4px 8px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 6px;
          background: var(--Primary-Blue, #005eff);
          color: #fff;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Montserrat, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 171.429% */
          letter-spacing: -0.2px;
        }
        .pro {
          text-transform: capitalize;
          display: flex;
          padding: 4px 8px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 6px;
          background: var(--Primary-Pro, #ff7020);
          color: #fff;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Montserrat, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 171.429% */
          letter-spacing: -0.2px;
        }
        .trial {
          display: flex;
          align-items: center;
          gap: 6px;
          position: relative;
          &.gift {
            justify-content: space-between;
          }
          .trialState {
            display: flex;
            padding: 2px 6px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 6px;
            opacity: 0.3;
            background: #5856d6;
            color: #ffffff;
            &.notPurchased {
              opacity: 1;
            }
          }
          .trialPlanPopup {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 100%;
            right: 10px;
            width: 304px;
            height: 293px;
            flex-shrink: 0;
            border-radius: 12px;
            background: #fff;
            box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
            z-index: 999;
            .trialPlanPopupTop {
              @include Noto-Sans-TCBody162-Medium;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 12px 8px;
              width: calc(100% - 24px);
              color: var(--gs008, #333);
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 36px; /* 225% */
              letter-spacing: 0.28px;
              border-bottom: 1px solid #eee;
              margin-bottom: 16px;
            }
            .trialPlanPopupMid {
              padding-left: 12px;
              display: flex;
              flex-direction: column;
              height: 138px;
              gap: 8px;
              border-bottom: 1px solid #eee;
              span {
                margin: 0px;
              }
              .divider {
                margin: 0;
                border: none;
                border-bottom: 1px solid #eee;
              }
              .trialPlanPopupMidRow {
                display: flex;
                align-items: center;
                gap: 8px;
                &.textEnd {
                  justify-content: flex-end;
                  margin-right: 8px;
                }
                .trialPlanPopupMidRowTitle {
                  display: flex;
                  color: #797979;
                  font-feature-settings: 'clig' off, 'liga' off;
                  min-width: 85px;
                  @include Noto-Sans-TCBody142-Medium;
                }
                .trialPlanPopupMidRowValue {
                  @include Noto-Sans-TCFootnote141-Regular;
                  color: #333;
                  white-space: nowrap;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 26px; /* 185.714% */
                  letter-spacing: 0.06px;
                }
              }
              .trialPlanPopupMidRowTip {
                @include Noto-Sans-TCCaption121-Regular;
                padding-left: 91px;
                margin-bottom: 24px;
                color: #797979;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px; /* 133.333% */
                letter-spacing: 0.05px;
              }
            }
            .trialPlanPopupBottom {
              display: flex;
              justify-content: flex-end;
              padding: 16px 8px;
              gap: 16px;
            }
          }
        }
      }

      .tableTd {
        @include Montserrat-Semi-Bold;
        font-size: 16px;
        color: $gs-008;
        vertical-align: middle;
      }

      .dropLists {
        cursor: pointer;
        vertical-align: middle;
        .actionWrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 7px;
        }
      }
    }

    .details {
      background-color: $gs-001;
      width: 100%;
      // transition: all 1s ease-out;
      @include Montserrat-Semi-Bold;
      font-size: 16px;
      border-radius: 16px;
      display: grid;
      grid-template-columns: 1fr 1fr;

      .detailsListContainer {
        display: flex;
        padding: 16px 32px;
        border-bottom: 1px solid $gs-003;
        &.first {
          align-items: flex-start;
        }
        small {
          font-size: 13px;
        }
        &:nth-last-child(2) {
          border-bottom: 0;
        }
        &:last-child {
          border-bottom: 0;
        }
      }

      .detailsListTitle {
        width: 20%;
        @include Noto-Sans-TCBody161-Regular;
        color: $gs-007;
        margin-right: 10px;
      }
    }
  }

  .paginationContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 98px;
    @include Noto-Sans-TCFootnote141-Regular;
    color: $gs-006;
    .pageDetail {
      width: 33.3%;
    }
  }
}

.dashboardPlanManagement {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  @include Montserrat-Semi-Bold;

  .tabsContainer {
    margin: 12px 56px 16px 72px;
  }

  .dashboardTitle {
    margin: 40px 56px 29px 72px;
    padding-bottom: 29px;
    @include Montserrat-Semi-Bold;
    border-bottom: 1px solid $gs-003;
    color: $gs-007;
  }

  .dashboardListContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 56px 20px 72px;

    .dashboardListTitle {
      @include Noto-Sans-TCTitle243-Bold;
      font-weight: 700;
      color: $gs-005;
      margin-bottom: 12px;
    }
  }

  .planEnable {
    margin: 20px 56px 20px 72px;

    .enableTitle {
      @include Noto-Sans-TCTitle243-Bold;
      color: $gs-005;
    }

    .enableDate {
      @include Noto-Sans-TCCallout162-Medium;
      color: $gs-005;
      margin-top: 12px;
    }

    .EnableContainer {
      .Enale {
        padding-top: 16px;
        padding-bottom: 32px;
        @include Noto-Sans-TCLarge-Title403-Bold;
      }
    }

    .nonEnableContainer {
      padding: 40px 0px 110px 0px;

      .nonEnale {
        @include Noto-Sans-TCLarge-Title403-Bold;
        margin-bottom: 12px;
      }

      .nonEnableAlert {
        @include Noto-Sans-TCCallout162-Medium;
      }
    }
  }
  .enableTrialPlan {
    background-image: url('../img/Plan.png');
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    padding: 27px 16px 16px 16px;
    border-radius: 8px;
    width: 22.75%;
    display: inline-block;
    margin: 10px 10px;
    vertical-align: top;
    &.pure {
      // margin: 0;
      width: 90% !important;
      margin: 0 5% 5% 5% !important;
    }
    .exploreIcon {
      color: $gs-000;
      width: 25px;
      height: 25px;
      margin-bottom: 8px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .title {
      color: $gs-000;
      @include Noto-Sans-TCLarge-Title323-Bold;
      margin-bottom: 16px;
    }
    .enableTrialDetail {
      display: flex;
      justify-content: space-between;
      .limitTitle {
        color: $gs-000;
        @include Noto-Sans-TCFootnote141-Regular;
      }
      .limitNum {
        color: #ffefb0;
        @include Noto-Sans-TCTitle243-Bold;
        span {
          color: $gs-000;
          @include Noto-Sans-TCFootnote141-Regular;
        }
      }
    }
  }
  .enablePlanWrapper {
    overflow-x: auto;
    transition: 0.5s;

    .enablePlan {
      box-sizing: border-box;
      padding: 16px;
      background-color: $gs-000;
      border-radius: 8px;
      width: 22.75%;
      display: inline-block;
      margin: 10px 10px;
      vertical-align: top;
      border: 2px solid $gs-002;

      .enablePlanNum {
        @include Noto-Sans-TCCaption122-Medium;
        color: $gs-004;
        padding-bottom: 8px;
      }

      .enablePlanTitle {
        @include Noto-Sans-TCLarge-Title343-Bold;
        padding-bottom: 8px;
      }

      .enablePlanEmployee {
        @include Montserrat-Semi-Bold;
        font-size: 14px;
        color: $system-blue;
        padding-bottom: 12px;
        line-height: 25px;
      }
      .planFlex {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $gs-003;
      }
      .MonthlyFee {
        @include Noto-Sans-TCBody142-Medium;
        color: $gs-005;
        line-height: 25px;
        padding-bottom: 4px;
      }
      span {
        @include Montserrat-Semi-Bold;
        font-size: 16px;
        color: $system-orange;
        margin-right: 4px;
      }
      .MonthlyFeeColor {
        display: inline;
      }
      .planFlexEnd {
        display: flex;
      }
      .SubPlanFlex {
        display: flex;
        margin-top: 12px;
        justify-content: space-between;
      }
      .enablePlanSubscribeFee {
        @include Noto-Sans-TCBody142-Medium;
        padding-top: 12px;
      }
    }
  }

  .enablePlanContainer {
    .enablePlan {
      box-sizing: border-box;
      padding: 16px;
      background-color: $gs-000;
      border-radius: 8px;
      width: 90% !important;
      margin: 0 5% 5% 5% !important;
      display: inline-block;
      border: 2px solid $gs-002;
      box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);

      .enablePlanNum {
        @include Noto-Sans-TCCaption122-Medium;
        color: $gs-004;
        padding-bottom: 8px;
      }

      .enablePlanTitle {
        @include Noto-Sans-TCLarge-Title343-Bold;
        padding-bottom: 8px;
      }

      .enablePlanEmployee {
        @include Montserrat-Semi-Bold;
        font-size: 14px;
        color: $system-blue;
        padding-bottom: 12px;
        line-height: 25px;
      }
      .planFlex {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $gs-003;
      }
      .MonthlyFee {
        @include Noto-Sans-TCBody142-Medium;
        color: $gs-005;
        line-height: 25px;
        padding-bottom: 4px;
      }
      span {
        @include Montserrat-Semi-Bold;
        font-size: 16px;
        color: $system-orange;
        margin-right: 4px;
      }
      .MonthlyFeeColor {
        display: inline;
      }
      .planFlexEnd {
        display: flex;
      }
      .SubPlanFlex {
        display: flex;
        margin-top: 12px;
        justify-content: space-between;
      }
      .enablePlanSubscribeFee {
        @include Noto-Sans-TCBody142-Medium;
        padding-top: 12px;
      }
    }
  }

  .nonStartPlan {
    margin: 20px 56px 20px 72px;

    .nonStartPlanTitle {
      @include Noto-Sans-TCTitle243-Bold;
      color: $gs-005;
      margin-bottom: 10px;
      margin-top: 40px;
    }

    .nonStartFlex {
      display: flex;
      justify-content: space-between;

      .searchOutline {
        position: relative;

        input {
          width: 225px;
          line-height: 27px;
          padding: 10px 8px 10px 16px;
          border: 0;
          font-size: 16px;
          font-weight: 400;
          border-radius: 12px;
          background: $system-pgrey;
        }

        .BsSearch {
          display: inline-block;
          position: absolute;
          right: 5%;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }
    }
  }

  .tableContainer {
    margin: 0px 56px 24px 72px;

    // background-color: green;
    table {
      width: 100%;

      th {
        color: $gs-006;
        font-size: 16px;
        border-bottom: 1px solid $gs-003;
        background-color: $system-table;
        box-sizing: border-box;
        &.reserve {
          table-layout: fixed;
          width: 200px;
        }
        &.time {
          width: 136px;
        }
        &.icons {
          max-width: 90px;
        }
      }

      th,
      .tableTd {
        padding: 8px 16px;
        border-bottom: 1px solid $gs-003;
        vertical-align: middle;
      }

      .tableTd {
        @include Noto-Sans-TCTitle163-Bold;
        font-size: 16px;
        color: $gs-008;
        vertical-align: middle;
        position: relative;
        .reserveDate {
          padding: 10px 0px;
        }
        .btnFlex {
          display: flex;
          align-items: center;
          button {
            margin-left: 10px;
          }
        }
      }
      .iconsCell {
        display: flex;
        height: 100%;
        justify-content: right;
        button {
          margin-right: 10px;
        }
      }
      .planReviewOuter {
        position: absolute;
        min-width: 384px;
        bottom: -20px;
        right: 90px;
        padding: 16px;
        background: $gs-000;
        border-radius: 16px;
        box-shadow: 2px 16px 40px rgba($gs-008, 0.2);
        z-index: 99;
        margin-bottom: 15px;
        .header {
          margin-bottom: 16px;
          display: flex;
          justify-content: space-between;
          .title {
            @include Noto-Sans-TCCallout183-Bold;
          }
        }
        .body {
          max-height: 430px;
          overflow-y: auto;
          .planOuter {
            background: $gs-001;
            padding: 16px;
            border-radius: 16px;
            border: 1px solid $gs-002;
            margin-bottom: 8px;
            .planCount {
              opacity: 0.7;
              @include Noto-Sans-TCCaption122-Medium;
              color: $gs-006;
            }
            .planName {
              @include Noto-Sans-TCTitle243-Bold;
              margin-bottom: 8px;
            }
            .tagOuter {
              display: flex;
              flex-wrap: wrap;
              gap: 6px;
              .tag {
                background: $secondary-color;
                padding: 4px 6px;
                color: $primary-color;
                @include Noto-Sans-TCBody152-Medium;
                border-radius: 8px;
                margin-bottom: 16px;
                &.discount {
                  display: flex;
                  gap: 6px;
                }
              }
            }
            .bundledOuter {
              background: $gs-000;
              border: 1px solid $gs-002;
              padding: 16px 12px;
              display: flex;
              justify-content: space-between;
              border-radius: 12px;
              align-items: center;
              margin-bottom: 12px;
              &:last-child {
                margin-bottom: 0;
              }
              .days {
                background: $primary-color;
                color: $gs-000;
                padding: 4px 6px;
                border-radius: 8px;
                @include Noto-Sans-TCFootnote151-Regular;
              }
              .bundleBox {
                color: rgba($gs-006, 0.7);
                @include Noto-Sans-TCCaption121-Regular;
                .fee {
                  @include Montserrat-Bold;
                  font-size: 16px;
                  color: $gs-009;
                }
                .discount {
                  @include Montserrat-Bold;
                  font-size: 16px;
                  color: $system-error;
                }
              }
            }
          }
          .trialPlanOuter {
            box-sizing: border-box;
            background-image: url('../img/Plan.png');
            margin: 8px 0;
            background-repeat: no-repeat;
            background-size: cover;
            padding: 30px 45px 40px 45px;
            border-radius: 16px;
            .explore {
              svg {
                color: rgba($gs-000, 0.7);
                font-size: 25px;
                margin-bottom: 8px;
              }
            }
            .name {
              @include Noto-Sans-TCTitle243-Bold;
              margin-bottom: 16px;
              color: $gs-000;
            }
            .detailsBoxOuter {
              display: flex;
              .detail {
                margin-right: 20px;
                .title {
                  color: rgba($gs-000, 0.8);
                  @include Noto-Sans-TCFootnote141-Regular;
                }
                .count {
                  @include Montserrat-Bold;
                  font-size: 24px;
                  color: $system-lightYellow;
                  span {
                    @include Noto-Sans-TCFootnote141-Regular;
                    color: $gs-005;
                  }
                }
              }
            }
          }
        }
      }

      .dropLists {
        cursor: pointer;
      }
    }

    .details {
      background-color: $gs-001;
      width: 100%;
      transition: all 1s ease-out;
      @include Montserrat-Semi-Bold;
      font-size: 16px;
      border-radius: 16px;

      .detailsListContainer {
        display: flex;
        align-items: center;
        padding: 16px 32px;
        border-bottom: 1px solid $gs-003;
      }

      .detailsListTitle {
        width: 20%;
        @include Noto-Sans-TCBody161-Regular;
        color: $gs-007;
      }
    }
  }

  .paginationContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 98px;
    @include Noto-Sans-TCFootnote141-Regular;
    color: $gs-006;
  }
}

.dashboardPayment {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  @include Montserrat-Semi-Bold;
  .dashboardTitle {
    margin: 40px 56px 29px 72px;
    padding-bottom: 29px;
    @include Montserrat-Semi-Bold;
    border-bottom: 1px solid $gs-003;
    color: $gs-007;
  }
  .tabsContainer {
    margin: 12px 56px 16px 72px;
  }
  .paymentList {
    margin: 20px 56px 20px 72px;
    .paymentListTitle {
      @include Noto-Sans-TCTitle243-Bold;
      color: $gs-005;
      margin-bottom: 10px;
    }
    .paymentListContainer {
      padding: 16px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $gs-003;
      @include Noto-Sans-TCCallout161-Regular;
    }
  }
}

.ModalContainer {
  position: relative;
  height: calc(100vh - 200px);
  // height: 100%;

  .bigLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.special {
      padding-top: 40px;
      padding-left: 32px;
      padding-right: 32px;
    }

    .modalTitle {
      @include Noto-Sans-TCTitle283-Bold;
      color: $gs-008;
    }

    .modalWrapper {
      width: 350px;
    }

    .modalClose {
      width: 40px;
      cursor: pointer;
      svg

      // :hover {
      //   background-color: $gs-002;
      // }

      .close {
        padding: 16px;
        border-radius: 12px;
      }
    }
  }

  .modalBody {
    margin-top: 24px;
    min-height: calc(100% - 180px);
    overflow-y: auto;
    &.createNewPlan {
      min-height: 0px;
      height: calc(100vh - 200px - 38px - 58px);
      max-height: calc(100vh - 200px - 38px - 58px);
      overflow-y: unset;
    }
    &.nonOverflow {
      height: calc(100% - 180px);
      overflow-y: auto;
      padding-left: 32px;
      padding-right: 32px;
    }
    &.nonOverflowPlus {
      height: calc(100% - 265px);
      min-height: calc(100% - 265px);
      overflow-y: auto;
      padding-left: 32px;
      padding-right: 32px;
    }
    .textPrimary {
      color: $primary-color;
      font-weight: 600;
    }
    .success {
      color: $system-success;
      font-weight: 500;
    }
    .failed {
      color: $system-error;
      font-weight: 500;
    }

    .planTitleContainer {
      display: flex;
      align-items: center;
      margin-bottom: 42px;

      .planTitle {
        @include Noto-Sans-TCCallout163-Bold;
        margin-right: 18px;
        position: relative;
        .titleErr {
          position: absolute;
          white-space: nowrap;
          top: calc(100% + 3px);
          left: calc(100% + 25px);
          color: $system-error;
          @include Noto-Sans-TCBody142-Medium;
        }
      }

      .titleInput {
        border: 0;
        background-color: $gs-002;
        padding: 6px 10px;
        @include Noto-Sans-TCCallout161-Regular;
        border-radius: 10px;
        min-width: 400px;
        max-width: 100%;
      }
      .planPeriod {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px;
        gap: 8px;
      }
    }

    //trial plan
    .TrialPlan {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 98.5%;
      border: 2px solid rgba(88, 86, 214, 0.3);
      padding: 14px 52px 18px 26px;
      margin-bottom: 8px;
      border-radius: 12px;
      .toggle {
        display: flex;
        width: 200px;
        padding: 12px;
        padding-left: 0;
        border-right: 2px solid $gs-002;
        margin-right: 12px;
        .title {
          @include Noto-Sans-TCCallout161-Regular;
          margin-left: 12px;
          color: $gs-007;
          font-weight: 500;
          &.disabled {
            color: $system-grey;
            font-weight: 400;
          }
        }
      }
      .limit {
        box-sizing: border-box;
        width: calc((100% - 248px) / 3);
        .title {
          @include Noto-Sans-TCFootnote142-Medium;
          color: $gs-008;
          margin-bottom: 8px;
          &.disabled {
            color: $gs-005;
          }
        }
        .errorMessage {
          color: $system-error;
          @include Noto-Sans-TCFootnote141-Regular;
        }
      }
    }

    .ModalTable {
      @include Noto-Sans-TCCallout161-Regular;
      line-height: 27px;
      color: $gs-007;
      width: 100%;

      tr {
        // display: flex;
        width: 100%;
        text-align: left;
      }

      th,
      td {
        padding: 10px;
        border-bottom: 1px solid $gs-002;
      }

      input {
        border: 0;
        background-color: $gs-002;
        padding: 6px 10px;
        line-height: 27px;
        border-radius: 10px;
        @include Noto-Sans-TCCallout161-Regular;
        &::placeholder {
          color: $system-grey;
        }
      }
      .errInput {
        border: 1px solid red;
        background-color: $gs-002;
        padding: 6px 10px;
        line-height: 27px;
        border-radius: 10px;
        @include Noto-Sans-TCCallout161-Regular;
      }
      .err {
        color: red;
        @include Noto-Sans-TCBody142-Medium;
      }

      .deletePlan {
        cursor: pointer;
      }
    }
    .planOuter {
      width: 100%;
      overflow: hidden;
      position: relative;
      height: 400px;
    }
    .planList {
      padding: 3px 5px 10px 5px;
      position: absolute;
      display: flex;
      top: 0;
      left: 0px;
      transition: transform 0.6s ease-in;

      .plan {
        box-sizing: border-box;
        width: 700px;
        padding: 20px 16px;
        background: $gs-000;
        box-shadow: 6px 4px 12px rgba($gs-008, 0.1);
        border-radius: 16px;
        margin-right: 12px;
        .planTop {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;
          .planCount {
            background: $secondary-color;
            padding: 4px 6px;
            @include Noto-Sans-TCBody152-Medium;
            color: $primary-color;
            border-radius: 8px;
          }
          .planDelete {
            color: $system-error;
          }
        }
        .planBody {
          display: flex;
          padding-bottom: 16px;
          border-bottom: 1px solid $gs-001;
          .box {
            margin-right: 8px;
            &:last-child {
              margin-right: 0px;
            }
            .inputTitle {
              @include Noto-Sans-TCCallout142-Medium;
              color: $gs-008;
              margin-bottom: 8px;
            }
            .countOption {
              width: 100%;
              display: flex;
              .dropdownOuter {
                margin-left: 8px;
                width: 150px;
              }
            }
            .errorMessage {
              color: $system-error;
              @include Noto-Sans-TCFootnote141-Regular;
            }
          }
        }
        .planFooter {
          margin-top: 16px;
          .bundledTop {
            display: flex;
            justify-content: space-between;
          }
          .bundledTitle {
            @include Noto-Sans-TCTitle163-Bold;
            color: $gs-008;
            margin-bottom: 12px;
            span {
              color: $gs-006;
              @include Noto-Sans-TCCaption132-Medium;
            }
          }
          .bundledOption {
            display: flex;
            width: 90px;
            justify-content: space-between;
          }
          .bundledOuter {
            width: 100%;
            overflow: hidden;
            position: relative;
            height: 132px;
          }
          .bundledList {
            padding: 3px 5px 10px 5px;
            position: absolute;
            display: flex;
            top: 0;
            left: 0px;
            transition: transform 0.6s ease-in;
          }
          .bundledBox {
            box-sizing: border-box;
            position: relative;
            display: flex;
            justify-content: space-between;
            padding: 12px;
            width: 316px;
            margin-right: 12px;
            background: $gs-000;
            border: 1px solid $gs-002;
            border-radius: 8px;
            .box {
              width: calc(33.3% - 8px);
              .inputTitle {
                @include Noto-Sans-TCCallout142-Medium;
                color: $gs-008;
                margin-bottom: 8px;
              }
            }
            .bundledDelete {
              position: absolute;
              top: 12px;
              right: 2px;
              color: $system-error;
              display: flex;
              svg {
                font-size: 15px;
              }
            }

            .errorMessage {
              color: $system-error;
              @include Noto-Sans-TCFootnote141-Regular;
            }
          }
        }
      }
    }
    .bgGradient {
      position: absolute;
      top: 0;
      height: 100%;
      width: 30px;
      z-index: 121;
      background: linear-gradient(270deg, #ffffff 33.59%, rgba(255, 255, 255, 0) 98.47%);
    }
    .swiper {
      position: absolute;
      top: 50%;
      padding: 16px 12px;
      background: rgba($gs-009, 0.2);
      border-radius: 8px;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.5s;
      z-index: 130;
      cursor: pointer;
      img {
        height: 56px;
      }
    }
    .left {
      left: 0px;
    }
    .right {
      right: 0;
    }
    .planLayout {
      display: flex;
      flex-direction: column;
      max-height: calc(100vh - 164px - 38px - 42px - 42px - 24px - 38px);
      overflow-x: hidden;
      overflow-y: auto;
      gap: 16px;
      .planCard {
        display: flex;
        width: 880px;
        padding: 20px 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        border-radius: 16px;
        border: 1px solid var(--Black-015, #d9d9d9);
        background: var(--gs000, #fff);
        .planCardTitle {
          display: flex;
          padding: 4px 6px;
          align-items: center;
          gap: 2px;
          border-radius: 8px;
          background: rgba(88, 86, 214, 0.1);
          color: #5856d6;
          text-align: center;
          font-feature-settings: 'clig' off, 'liga' off;
          @include Noto-Sans-TCFootnote152-Medium;
        }
        .planCardTop {
          display: flex;
          width: 100%;
          justify-content: space-between;
        }
        .planCardMid {
          display: flex;
          gap: 8px;
        }
        .planCardBottom {
          display: flex;
          gap: 8px;
        }
        .planCardSelectTitleLeft {
          display: flex;
          align-items: center;
          width: 125px;
          flex-shrink: 0;
          color: var(--gs008, #333);
          font-feature-settings: 'clig' off, 'liga' off;
          @include Noto-Sans-TCBody142-Medium;
        }
        .planCardSelectTitleMid {
          display: flex;
          align-items: center;
          width: 77px;
          flex-shrink: 0;
          color: var(--gs008, #333);
          font-feature-settings: 'clig' off, 'liga' off;
          @include Noto-Sans-TCBody142-Medium;
        }
        .planCardSelectTitleRight {
          display: flex;
          align-items: center;
          width: 105px;
          flex-shrink: 0;
          color: var(--gs008, #333);
          font-feature-settings: 'clig' off, 'liga' off;
          @include Noto-Sans-TCBody142-Medium;
        }
        &:last-child {
          margin-bottom: 30px;
        }
      }
    }
  }
  .errorMessage {
    color: $system-error;
    @include Noto-Sans-TCFootnote141-Regular;
  }

  .modalFooter {
    position: sticky;
    top: 0;
    left: 0;
    border-top: 1px solid $gs-002;
    margin-top: 10px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.normal {
      display: block;
      padding-left: 32px;
      padding-right: 32px;
      padding-top: 0;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    }
    .errorImply {
      color: $system-red;
      @include Noto-Sans-TCCallout161-Regular;
    }
  }
}

@media (min-width: 1024px) {
  .inner-layout {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

@media (max-width: $media-xl) {
  .inner-layout {
    left: 0;
    width: 100%;
  }
}

// new enable plan (啟用中方案)
.isEnabledPlan {
  margin: 20px 56px 20px 72px;
  background: $gs-000;
  border-radius: 20px;
  box-shadow: 0px 0px 24px rgba($gs-009, 0.16);
  padding: 16px;
  .enabledHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
    .enableShow {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 121px;
      height: 53px;
      border-radius: 40px;
      gap: 10px;
      background: $system-green;
      color: $gs-000;
      @include Noto-Sans-TCTitle163-Bold;
      margin-right: 16px;
    }
    .titleBox {
      .title {
        @include Noto-Sans-TCTitle283-Bold;
      }
      .date {
        @include Noto-Sans-TCFootnote151-Regular;
        color: $gs-006;
        span {
          @include Montserrat-Medium;
          font-weight: 500;
          font-size: 15px;
        }
      }
    }
  }
  .enableBody {
    .planOuter {
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      max-height: 360px;
      overflow: auto;
    }
    .trialPlanOuter {
      box-sizing: border-box;
      background-image: url('../img/Plan.png');
      width: calc(50% - 8px);
      margin: 8px 16px 8px 0;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 70px 45px 85px 45px;
      border-radius: 16px;
      .explore {
        svg {
          color: rgba($gs-000, 0.7);
          font-size: 25px;
          margin-bottom: 8px;
        }
      }
      .name {
        @include Noto-Sans-TCTitle243-Bold;
        margin-bottom: 16px;
        color: $gs-000;
      }

      .detailsBoxOuter {
        display: flex;
        .detail {
          margin-right: 20px;
          .title {
            color: rgba($gs-000, 0.8);
            @include Noto-Sans-TCFootnote141-Regular;
          }
          .count {
            @include Montserrat-Bold;
            font-size: 24px;
            color: $system-lightYellow;
            span {
              @include Noto-Sans-TCFootnote141-Regular;
              color: $gs-005;
            }
          }
        }
      }
    }
    .enablePlan {
      box-sizing: border-box;
      width: calc(50% - 8px);
      background: $gs-001;
      border-radius: 16px;
      border: 1px solid $gs-002;
      padding: 16px;
      &:nth-child(odd) {
        margin: 8px 16px 8px 0;
      }
      &:nth-child(even) {
        margin: 8px 0;
      }
      .planCount {
        opacity: 0.7;
        @include Noto-Sans-TCCaption122-Medium;
        color: $gs-006;
        margin-bottom: 2px;
      }
      .name {
        @include Noto-Sans-TCTitle243-Bold;
        margin-bottom: 8px;
      }
      .tagOuter {
        display: flex;
        .tag {
          background: $secondary-color;
          padding: 4px 6px;
          color: $primary-color;
          @include Noto-Sans-TCBody152-Medium;
          border-radius: 8px;
          margin-bottom: 16px;
          &:nth-child(1) {
            margin-right: 6px;
          }
        }
      }
      .bundledBox {
        max-height: 182px;
        overflow: auto;
      }
      .bundledOuter {
        background: $gs-000;
        border: 1px solid $gs-002;
        padding: 16px 12px;
        display: flex;
        justify-content: space-between;
        border-radius: 12px;
        align-items: center;
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0px;
        }
        &.plan {
          flex-direction: column;
          .bundledOuterTop {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
          }
          .bundledOuterBottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
          }
          @media (max-width: 1280px) {
            .bundledOuterTop {
              display: block;
              .bundleBox {
                display: flex;
                margin: 4px 0px;
                align-items: center;
                gap: 10px;
                width: 100% !important;
              }
            }
            .bundledOuterBottom {
              margin-top: -6px;
              display: block;
              .bundleBox {
                display: flex;
                margin: 4px 0px;
                align-items: center;
                gap: 10px;
                width: 100% !important;
              }
            }
          }
        }
        .days {
          background: $primary-color;
          color: $gs-000;
          padding: 4px 6px;
          border-radius: 8px;
          @include Noto-Sans-TCFootnote151-Regular;
        }
        .bundleBox {
          color: rgba($gs-006, 0.7);
          @include Noto-Sans-TCCaption121-Regular;
          .fee {
            @include Montserrat-Bold;
            font-size: 16px;
            color: $gs-009;
          }
          .discount {
            @include Montserrat-Bold;
            font-size: 16px;
            color: $system-error;
          }
        }
      }
    }
  }
}

.trialPlanModal {
  .trialPlanModalTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 32px;
    width: 100%;
    color: var(--gs008, #333);
    font-feature-settings: 'clig' off, 'liga' off;
    @include Noto-Sans-TCTitle283-Bold;
  }
  .divider {
    margin-left: -32px;
    width: calc(100% + 64px);
    height: 1px;
    flex-shrink: 0;
    background: var(--Black-015, #d9d9d9);
  }
  .trialPlanModalMid {
    margin-top: 24px;
    height: 235px;
    .trialPlanModalMidTop {
      display: flex;
      align-items: center;
      height: 37px;
      margin-bottom: 16px;
      gap: 8px;
      &.fixedWidth {
        width: 200px;
        .leftTitle {
          width: 140px;
        }
      }
      .leftTitle {
        width: 80px;
        color: var(--gs008, #333);
        font-feature-settings: 'clig' off, 'liga' off;
        white-space: nowrap;
        @include Noto-Sans-TCBody142-Medium;
      }
      .rightTitle {
        margin-left: 8px;
        width: 85px;
        color: var(--gs008, #333);
        font-feature-settings: 'clig' off, 'liga' off;
        white-space: nowrap;
        @include Noto-Sans-TCBody142-Medium;
      }
    }
    .trialPlanModalMidMid {
      display: flex;
      align-items: center;
      height: 37px;
      margin-bottom: 10px;
      gap: 8px;
      color: var(--gs008, #333);
      font-feature-settings: 'clig' off, 'liga' off;
      white-space: nowrap;
      @include Noto-Sans-TCBody142-Medium;
      .leftTitle {
        width: 80px;
        color: var(--gs008, #333);
        font-feature-settings: 'clig' off, 'liga' off;
        white-space: nowrap;
        @include Noto-Sans-TCBody142-Medium;
      }
    }
    .trialPlanModalMidMidTip {
      @include Noto-Sans-TCCaption121-Regular;
      padding-left: 88px;
      margin-bottom: 24px;
      color: #797979;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.05px;
    }
    .trialPlanModalMidBottom {
      @include Noto-Sans-TCFootnote141-Regular;
      display: flex;
      align-items: center;
      gap: 12px;
      color: var(--gs008, #333);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 25px; /* 178.571% */
      letter-spacing: 0.05px;
    }
  }
  .trialPlanModalBottom {
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;
    gap: 24px;
  }
}
.helpBtnOuter {
  display: flex;
  align-items: center;
  position: relative;
  .tips {
    span {
      display: block;
    }
    position: absolute;
    top: 25px;
    right: -50%;
    width: 228px;
    background: rgba($gs-008, 0.9);
    @include Noto-Sans-TCCaption121-Regular;
    padding: 6px 8px;
    border-radius: 6px;
    color: $gs-000;
    z-index: 99;
  }
}
