@import "../../../../style/main.scss";

.emptyBox {
  border-radius: 8px;
  margin-top: 12px;
  padding: 32px;
  text-align: center;
  margin-bottom: 13px;
  position: relative;
  min-height: 160px;
  img {
    max-width: 100%;
    width: 300px;
  }
  .wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translateX(-50%) translateY(-50%);
    .text {
      @include Noto-Sans-TCBody162-Medium;
      margin-bottom: 16px;
      color: $gs-007;
    }
  }
}
