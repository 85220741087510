@import "../../../../style/main.scss";

.groupTag {
  background-color: $primary-color;
  border-radius: 8px;
  color: $gs-000;
  width: fit-content;
  display: inline-block;
  padding: 4px 6px;
  margin: 0 3px;
  text-transform: uppercase;
  font-weight: 600;

  .tagContent {
    display: flex;
    align-items: center;
    justify-content: center;

    .displaytitle {
      display: block;
      @include Noto-Sans-TCCallout142-Medium;
      @include Montserrat-Medium;
      font-size: 13px;
      font-weight: 500;
    }
    .title {
      display: block;
      margin-right: 6px;
      @include Noto-Sans-TCCallout142-Medium;
    }
    .tagIcon {
      display: flex;
      align-items: center;
      font-size: 20px;
      cursor: pointer;
    }
  }
}
