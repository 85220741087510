@charset "utf-8";
@import '../../../../style/main.scss';

%normal-button {
  @include Noto-Sans-TCCallout152-Medium;
  height: 37px;
  padding: 5px 12px 6px 12px;
  box-sizing: border-box;
  border-radius: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: $zIndex-button;
  cursor: pointer;
  position: relative;
  &.fullWidth {
    width: 100%;
  }
  &.only-icon {
    padding: 6px 6px 7px;
    &.icon-before {
      padding: 6px 6px 7px;
    }
    &.icon-after {
      padding: 6px 6px 7px;
    }
  }
  &.icon-before {
    padding: 5px 12px 6px 8px;
  }
  &.icon-after {
    padding: 5px 8px 6px 12px;
  }
  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    display: block;
    overflow: hidden;
    opacity: 0;
    input[type='file'] {
      height: 37px;
      cursor: pointer;
    }
  }
}

%large-button {
  height: 48px;
  padding: 11px 24px;
  z-index: $zIndex-button;
}

.button-primary {
  // @extend %normal-button;
  @include Noto-Sans-TCCallout152-Medium;
  height: 37px;
  padding: 5px 12px 6px 12px;
  box-sizing: border-box;
  border-radius: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: $zIndex-button;
  cursor: pointer;
  position: relative;
  &.fullWidth {
    width: 100%;
  }
  &.only-icon {
    padding: 6px 6px 7px;
    &.icon-before {
      padding: 6px 6px 7px;
    }
    &.icon-after {
      padding: 6px 6px 7px;
    }
  }
  &.icon-before {
    padding: 5px 12px 6px 8px;
  }
  &.icon-after {
    padding: 5px 8px 6px 12px;
  }
  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    display: block;
    overflow: hidden;
    opacity: 0;
    input[type='file'] {
      height: 37px;
      cursor: pointer;
    }
  }
  //
  border: 1px solid transparent;
  color: $gs-000;
  background: $primary-color;
  &:hover {
    backdrop-filter: brightness(40%);
  }
  &:active {
    backdrop-filter: brightness(10px);
  }
}

.button-primary.large {
  // @extend %large-button;
  height: 48px;
  padding: 11px 24px;
  z-index: $zIndex-button;
  @include Noto-Sans-TCCallout163-Bold;
}

.button-primary.small {
  // @extend %large-button;
  height: 33px;
  padding: 3px 8px;
  z-index: $zIndex-button;
  @include Noto-Sans-TCCallout163-Bold;
  font-size: 14px;
  margin-top: 5px;
}

.button-secondary {
  // @extend %normal-button;
  @include Noto-Sans-TCCallout152-Medium;
  height: 37px;
  padding: 5px 12px 6px 12px;
  box-sizing: border-box;
  border-radius: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: $zIndex-button;
  cursor: pointer;
  position: relative;
  &.fullWidth {
    width: 100%;
  }
  &.only-icon {
    padding: 6px 6px 7px;
    &.icon-before {
      padding: 6px 6px 7px;
    }
    &.icon-after {
      padding: 6px 6px 7px;
    }
  }
  &.icon-before {
    padding: 5px 12px 6px 8px;
  }
  &.icon-after {
    padding: 5px 8px 6px 12px;
  }
  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    display: block;
    overflow: hidden;
    opacity: 0;
    input[type='file'] {
      height: 37px;
      cursor: pointer;
    }
  }
  //
  border: 1px solid $gs-003;
  color: $primary-color;
  background-color: $gs-000;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  &:hover {
    background-color: $gs-003;
  }
  &:active {
    background-color: rgba($primary-color, 0.1);
  }
}

.button-secondary.large {
  // @extend %large-button;
  height: 48px;
  padding: 11px 24px;
  z-index: $zIndex-button;
  @include Noto-Sans-TCCallout163-Bold;
  border: 3px solid $gs-008;
}

.button-tertiary {
  // @extend %normal-button;
  @include Noto-Sans-TCCallout152-Medium;
  height: 37px;
  padding: 5px 12px 6px 12px;
  box-sizing: border-box;
  border-radius: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: $zIndex-button;
  cursor: pointer;
  position: relative;
  &.fullWidth {
    width: 100%;
  }
  &.only-icon {
    padding: 6px 6px 7px;
    &.icon-before {
      padding: 6px 6px 7px;
    }
    &.icon-after {
      padding: 6px 6px 7px;
    }
  }
  &.icon-before {
    padding: 5px 12px 6px 8px;
  }
  &.icon-after {
    padding: 5px 8px 6px 12px;
  }
  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    display: block;
    overflow: hidden;
    opacity: 0;
    input[type='file'] {
      height: 37px;
      cursor: pointer;
    }
  }
  //
  border: none;
  background: rgba($primary-color, 0.2);
  color: $primary-color;
  &:hover {
    background: $gs-004;
  }
}

.button-tertiary.large {
  // @extend %large-button;
  height: 48px;
  padding: 11px 24px;
  z-index: $zIndex-button;
  @include Noto-Sans-TCCallout162-Medium;
}

.button-quaternary {
  // @extend %normal-button;
  @include Noto-Sans-TCCallout152-Medium;
  height: 37px;
  padding: 5px 12px 6px 12px;
  box-sizing: border-box;
  border-radius: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: $zIndex-button;
  cursor: pointer;
  position: relative;
  &.fullWidth {
    width: 100%;
  }
  &.only-icon {
    padding: 6px 6px 7px;
    &.icon-before {
      padding: 6px 6px 7px;
    }
    &.icon-after {
      padding: 6px 6px 7px;
    }
  }
  &.icon-before {
    padding: 5px 12px 6px 8px;
  }
  &.icon-after {
    padding: 5px 8px 6px 12px;
  }
  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    display: block;
    overflow: hidden;
    opacity: 0;
    input[type='file'] {
      height: 37px;
      cursor: pointer;
    }
  }
  //
  border: none;
  background: transparent;
  color: $primary-color;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}

.button-icon {
  font-size: 22px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 4px;
  margin-left: -3px;
  &.icon-after {
    margin-left: 4px;
    margin-right: -3px;
    justify-content: flex-end;
  }
  &.only-icon {
    margin: 0;
    justify-content: center;
  }
}

.button-disable {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}

.button-subsidiary {
  // @extend %normal-button;
  @include Noto-Sans-TCCallout152-Medium;
  height: 37px;
  box-sizing: border-box;
  border-radius: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: $zIndex-button;
  cursor: pointer;
  position: relative;
  &.fullWidth {
    width: 100%;
  }
  &.only-icon {
    padding: 6px 6px 7px;
    &.icon-before {
      padding: 6px 6px 7px;
    }
    &.icon-after {
      padding: 6px 6px 7px;
    }
  }
  &.icon-before {
    padding: 5px 12px 6px 8px;
  }
  &.icon-after {
    padding: 5px 8px 6px 12px;
  }
  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    display: block;
    overflow: hidden;
    opacity: 0;
    input[type='file'] {
      height: 37px;
      cursor: pointer;
    }
  }
  //
  border: none;
  background: transparent;
  color: $primary-color;
  &:hover {
    text-decoration-line: underline;
  }
  padding: 8px 12px 9px;
}

.button-pure {
  // @extend %normal-button;
  @include Noto-Sans-TCCallout152-Medium;
  box-sizing: border-box;
  justify-content: center;
  z-index: $zIndex-button;
  cursor: pointer;
  position: relative;
  &.fullWidth {
    width: 100%;
  }
  &.only-icon {
    padding: 6px 6px 7px;
    &.icon-before {
      padding: 6px 6px 7px;
    }
    &.icon-after {
      padding: 6px 6px 7px;
    }
  }
  &.icon-before {
    padding: 5px 12px 6px 8px;
  }
  &.icon-after {
    padding: 5px 8px 6px 12px;
  }
  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    display: block;
    overflow: hidden;
    opacity: 0;
    input[type='file'] {
      height: 37px;
      cursor: pointer;
    }
  }
  //
  border: none;
  background: transparent;
  color: $primary-color;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  padding: 0 !important;
}

.rotate180 {
  transform: rotate(180deg);
  transition: 0.3s;
}

.animateSpin {
  animation: spin 1s linear infinite;
  width: 20px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
