@import '../../../style/main.scss';

@mixin small {
  @media (max-width: 1440px) {
    @content;
  }
}

.overviewOuter {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: calc(100vw - 350px);
  height: 100vh;
  margin: 45px 32px 45px 64px;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
    .headerLeft {
      display: flex;
      .loadingOuter {
        width: 200px;
      }
      .statusBtn {
        margin-right: 8px;
        background: $status-backgroundPink;
        border: 1px solid $status-pink;
        color: $status-pink;
        padding: 5px 11px 3px 11px;
        border-radius: 16px;
        @include Noto-Sans-TCCallout143-Bold;
        line-height: 25px;
        text-align: center;
      }
      .headerTitle {
        color: $gs-007;
        @include Noto-Sans-TCTitle243-Bold;
      }
    }
  }
  .subHeader {
    padding-bottom: 6px;
    border-bottom: 1px solid $gs-002;
    display: flex;
    .drodownOuter {
      width: 120px;
      &:nth-child(1) {
        margin-right: 8px;
      }
    }
  }
  .body {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .bodyLeft {
      width: 35%;
      .overview {
        .overviewTitle {
          color: $gs-008;
          @include Noto-Sans-TCTitle163-Bold;
        }
        .overviewContent {
          padding: 16px;
          margin-top: 12px;
          background: rgba($tdp-secondaryGreen, 0.1);
          border: 1px solid rgba(0, 0, 0, 0.05);
          margin-bottom: 18px;
          border-radius: 18px;
          display: flex;
          justify-content: space-between;
          @include small {
            flex-wrap: wrap;
          }
          .contentLeft {
            width: 60%;
            @include small {
              width: 100%;
            }
            .lifecycle {
              opacity: 1 !important;
              background: rgba($tdp-secondaryGreen, 0.8);
              height: 172px;
              border-radius: 12px;
              .title {
                text-align: center;
                @include Noto-Sans-TCFootnote151-Regular;
                color: $gs-000;
                margin-bottom: 2px;
                padding-top: 16px;
              }
              .cycleName {
                text-align: center;
                color: $gs-000;
                margin-bottom: 25px;
                @include Noto-Sans-TCTitle243-Bold;
              }
              .cycleImg {
                display: flex;
                justify-content: center;
                img {
                  width: 190px;
                }
              }
            }
            .department {
              margin-top: 12px;
              width: calc(100% - 32px);
              background: $gs-000;
              border-radius: 12px;
              padding: 10px 16px 13px 16px;
              box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.05);
              .title {
                @include Noto-Sans-TCFootnote151-Regular;
              }
              .count {
                @include Montserrat-Bold;
                font-size: 24px;
                color: $tdp-secondaryGreen;
                margin-top: 2px;
              }
            }
          }
          .contentRight {
            width: 38%;
            margin-left: 12px;
            @include small {
              width: 100%;
              margin-left: 0px;
            }
            .management,
            .manager,
            .employee {
              margin-bottom: 12px;
              width: calc(100% - 32px);
              background: $gs-000;
              border-radius: 12px;
              padding: 10px 16px 13px 16px;
              box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.05);
              .title {
                @include Noto-Sans-TCFootnote151-Regular;
              }
              .count {
                @include Montserrat-Bold;
                font-size: 24px;
                color: $tdp-secondaryGreen;
                margin-top: 4px;
              }
            }
            .management {
              @include small {
                margin-top: 12px;
              }
            }
          }
        }
      }
      .evaluation {
        .numbering {
          margin-left: 32px;
        }
      }
      .icon {
        font-size: 24px;
        margin-top: 8px;
      }
      .evaluationTitle {
        color: $gs-008;
        @include Noto-Sans-TCTitle163-Bold;
        margin-bottom: 12px;
      }
    }
    .bodyRight {
      width: 63%;
      .usageCondition {
        .usageConditionTitle {
          color: $gs-008;
          @include Noto-Sans-TCTitle163-Bold;
          margin-bottom: 12px;
        }
        .usageContent {
          border: 1px solid rgba($gs-009, 0.1);
          background: $gs-000;
          padding: 20px 32px;
          border-radius: 16px;
          margin-bottom: 30px;
          .usageCount {
            .usageCountTitle {
              @include Noto-Sans-TCCallout152-Medium;
              color: rgba($gs-009, 0.8);
              margin-bottom: 10px;
            }
            .strategyOuter {
              border: 1px solid rgba($gs-009, 0.1);
              background: $gs-000;
              padding: 24px 32px;
              border-radius: 16px;
              display: flex;
              justify-content: space-between;
              .card {
                width: 31%;
                &:nth-child(2) {
                  width: 28%;
                }
                &:nth-child(3) {
                  width: 30%;
                }
                .strategyTtitle {
                  color: $gs-007;
                  @include Noto-Sans-TCFootnote151-Regular;
                  margin-bottom: 2px;
                }
                .strategyCount {
                  @include Montserrat-Bold;
                  font-size: 24px;
                  color: $primary-color;
                }
              }
            }
          }
        }
        .completeRatio {
          margin-top: 18px;
          .completeRatioTitle {
            @include Noto-Sans-TCCallout152-Medium;
            color: rgba($gs-009, 0.8);
            margin-bottom: 10px;
          }
          .ratioCardOuter {
            display: flex;
            justify-content: space-between;
            flex-grow: 1;
            @include small {
              flex-wrap: wrap;
            }
            .completeCard {
              background: $gs-000;
              box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
              border-radius: 12px;
              padding: 16px 12px;
              width: 28%;
              @include small {
                width: 42.5%;
                &:nth-child(3) {
                  margin-top: 30px;
                }
              }
              .donut {
                position: relative;
                margin-bottom: 22px;
                .percentage {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  @include Montserrat-Bold;
                  font-size: 24px;
                  color: $gs-009;
                }
              }
              .title {
                display: flex;
                justify-content: center;
                color: $gs-007;
                @include Noto-Sans-TCFootnote151-Regular;
                margin-bottom: 16px;
              }
              table {
                width: 100%;
                thead {
                  th {
                    @include Noto-Sans-TCCaption121-Regular;
                    color: $gs-004;
                    padding-bottom: 5px;
                    border-bottom: 1px solid $gs-004;
                    background: none;
                    &:nth-child(2) {
                      text-align: left;
                      padding-left: 0;
                      padding-right: 0;
                    }
                    &:nth-child(3) {
                      padding-left: 0;
                      padding-right: 0;
                      text-align: right;
                    }
                  }
                }
                tbody {
                  td {
                    color: $gs-007;
                    @include Noto-Sans-TCFootnote142-Medium;
                    text-align: center;
                    padding: 0;
                    span {
                      margin: 0;
                    }
                    &:nth-child(2) {
                      text-align: left;
                    }
                    &:nth-child(3) {
                      text-align: right;
                      @include Montserrat-Bold;
                      font-size: 14px;
                    }
                    .dot {
                      width: 8px;
                      height: 8px;
                      border-radius: 50%;
                      display: inline-block;
                      margin-right: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
