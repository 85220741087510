@import "../../../../style/main.scss";

.tableComponent {
  width: 100%;
  box-sizing: border-box;
  thead {
    tr {
      th {
        box-sizing: border-box;
        @include Noto-Sans-TCCaption132-Medium;
        padding: 10px 16px;
        vertical-align: middle;
        position: sticky;
        top: 0;
        overflow: visible;
        z-index: 3;
        background-color: $third-color;
        color: $gs-006;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        transition: 0.5s;
        background: $gs-001;
      }
      td {
        @include Noto-Sans-TCFootnote142-Medium;
        background-color: $gs-000;
        padding: 12.5px 16px;
        vertical-align: middle;
        // div {
        //   div {
        //     display: flex;
        //     justify-content: flex-end;
        //     align-items: center;
        //   }
        // }
      }
    }
  }
  &.condensed {
    tbody {
      tr {
        td {
          @include Noto-Sans-TCFootnote151-Regular;
          padding: 7.5px 16px;
        }
      }
    }
  }
  .btn-img {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      display: block;
      width: 24px;
    }
  }
  .rotate180 {
    transform: rotate(180deg);
    transition: 0.3s;
  }
  .rotate0 {
    transition: 0.3s;
  }
}
.arrow {
  display: flex;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
}
.titleFocus {
  // color: $primary-color;
  color: $gs-006;
}
