@import "../../../../style/main.scss";

// .wrapper {
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .tips {
//   @include Noto-Sans-TCCaption121-Regular;
// }

// .tips {
//   box-sizing: border-box;
//   padding: 6px;
//   border-radius: 6px;
//   position: absolute;
//   background: $gs-008;
//   opacity: 0.9;
//   color: $gs-000;
//   left: 0;
//   z-index: 20;
//   // width: 280px;
//   width: 100%;
//   max-width: 280px;
//   white-space: wrap;
//   @include Noto-Sans-TCCaption121-Regular;
// }

.tips {
  cursor: default;
  box-sizing: border-box;
  padding: 6px;
  border-radius: 6px;
  position: absolute;
  background: $gs-008;
  opacity: 0.9;
  color: $gs-000;
  left: 0;
  z-index: 999;
  max-width: 280px;
  white-space: nowrap;
  @include Noto-Sans-TCCaption121-Regular;
  &.penetrate {
    pointer-events: none;
  }
}
